import { PaymentListFilter, PaymentListItem } from '@/models/dictionary/payment-list.model';
import { api } from '@/services/http.service';
import { ElNotification } from 'element-plus';
import { defineStore } from 'pinia';

interface PaymentListState {
  paymentList: PaymentListItem[];
  isPaymentListLoading: boolean;
}

export const usePaymentListStore = defineStore({
  id: 'PaymentListStore',
  state: (): PaymentListState => ({
    paymentList: [],
    isPaymentListLoading: false,
  }),
  getters: {
    uniquePaymentList: (state: PaymentListState): PaymentListItem[] => {
      return Array.from(
        new Map(state.paymentList.map((item: PaymentListItem) => [item.psp, item])).values(),
      );
    },
  },
  actions: {
    setPaymentListLoading(isLoading: boolean): void {
      this.isPaymentListLoading = isLoading;
    },
    getPaymentList(filterList?: PaymentListFilter): Promise<PaymentListItem[] | void> {
      this.setPaymentListLoading(true);

      return api.dictionary.getPaymentListRequest.perform({ filterList })
        .then((paymentList: PaymentListItem[]) => {
          this.paymentList = paymentList;

          return paymentList;
        })
        .catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });

          return Promise.reject(error);
        })
        .finally(() => {
          this.setPaymentListLoading(false);
        });
    },
  },
});
