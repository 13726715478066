import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { AvailableWhitelabelConfigItem } from '@/models/multibrand/multibrand.model';

export default class GetAvailableWhitelabelConfigListRequest
  extends ApiResourceMethod<AvailableWhitelabelConfigItem[]> {
  public perform(): Promise<AvailableWhitelabelConfigItem[]> {
    return this.performer.requestWithMapper(
      {},
      (payload: BaseResponse<AvailableWhitelabelConfigItem[]>) => {
        return payload.data;
      },
    );
  }
}
