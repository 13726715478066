import { defineStore, storeToRefs } from 'pinia';
import { api } from '@/services/http.service';
import { ElNotification } from 'element-plus';
import { usePspSortingListFilterStore } from '@/store/psp-sorting/psp-sorting-list-filter.store';
import {
  PspSortingListItem,
  ExtendedPspSortingItem,
  PspItem,
  BrandFilter,
} from '@/models/psp-sorting/psp-sorting.model';

type PspSortingStore = {
  isPspSortingListLoading: boolean;
  pspSortingList: PspSortingListItem[];
}

export const usePspSortingStore = defineStore({
  id: 'PspSortingStore',
  state: (): PspSortingStore => ({
    isPspSortingListLoading: false,
    pspSortingList: [],
  }),
  getters: {
    filteredPspSortingList(state: PspSortingStore): PspSortingListItem[] {
      const { pspSortingFilterFormData } = storeToRefs(usePspSortingListFilterStore());

      if (!pspSortingFilterFormData.value.brand?.length) {
        return state.pspSortingList;
      }

      return state.pspSortingList.filter(psp => pspSortingFilterFormData.value.brand?.includes(psp.brandName));
    },
    extendedPspSortingList(): ExtendedPspSortingItem[] {
      return this.filteredPspSortingList.flatMap((item: PspSortingListItem) =>
        item.pspList.map((psp: PspItem, index: number) => ({
          brandName: index === 0 ? item.brandName : '',
          lastUpdated: item.lastUpdated,
          paymentGateway: psp.paymentGateway,
          paymentSystem: psp.paymentSystem,
          imageUrl: psp.imageUrl,
          rowspan: item.pspList.length,
          isFirst: index === 0,
        })),
      );
    },
    brandFilters: (state: PspSortingStore): BrandFilter[] => {
      return Array.from(
        new Set(
          state.pspSortingList
            .map((item: { brandName?: string }) => item.brandName)
            .filter((brandName): brandName is string => Boolean(brandName)),
        ),
      ).map((name) => ({
        label: name,
        value: name,
      }));
    },
  },
  actions: {
    setPspSortingListLoading(isLoading: boolean): void {
      this.isPspSortingListLoading = isLoading;
    },
    getPspSortingList(): Promise<PspSortingListItem[]> {
      this.setPspSortingListLoading(true);

      const pspSortingListFilterStore = usePspSortingListFilterStore();
      const { pspSortingFilterFormData } = storeToRefs(pspSortingListFilterStore);
      const { resetPspSortingFilterBrand } = pspSortingListFilterStore;

      resetPspSortingFilterBrand();

      return api.pspSorting.getPspSortingListRequest.perform({ country: pspSortingFilterFormData.value.country })
        .then((pspSortingList: PspSortingListItem[]) => {
          this.pspSortingList = pspSortingList;

          return pspSortingList;
        }).catch(error => {
          ElNotification({
            title: 'Error',
            message: error.response.data.message,
            type: 'error',
          });

          return [];
        })
        .finally(() => {
          this.setPspSortingListLoading(false);
        });
    },
    resetPspSortingList(): void {
      this.pspSortingList = [];
    },
  },
});
