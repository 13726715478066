export type SortValue = {
  columnName: string;
  order: string;
}

export enum SortingMapping {
  ascending = 'a',
  descending = 'd',
}

export enum TagComponentTypes {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  DANGER = 'danger',
}

export enum StatusTypes {
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  DANGER = 'danger',
  DEFAULT = 'default',
}
