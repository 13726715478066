import { storeToRefs } from 'pinia';
import { computed, defineComponent, onMounted } from 'vue';
import { Setting, Delete, DCaret } from '@element-plus/icons-vue';
import {
  geoPaymentMethodColumnsList,
  GeoPaymentMethodColumnProperties,
  GeoPaymentMethodPartialUpdateParams,
  GeoPaymentMethodOperation,
  GeoPaymentMethodItem,
  GeoPaymentMethodPartialUpdateParameterKeyModel,
} from '@/models/ps-management/ps-management.model';
import PsLimitsDetails from '@/layouts/ps-management-page/ps-limits-details/ps-limits-details.vue';
import { useGeoPsManagementStore } from '@/store/ps-management/geo-payment-methods.store';
// eslint-disable-next-line import/no-named-as-default
import Sortable from 'sortablejs';
import { useDragListSorting } from '@/components/drag-sorting/drag-sorting.hook';
import { ElMessageBox } from 'element-plus';
import { usePaymentMethodsDepositLimitsStore } from '@/store/ps-management/payment-methods-deposit-limits.store';
import { usePermissionsStore } from '@/store/permissions.store';
import { PS_MANAGEMENT_PERMISSIONS } from '@/models/app-internal/permissions.model';

const icons = {
  Setting,
  Delete,
  DCaret,
};

export default defineComponent({
  name: 'GeoPaymentMethodsList',
  components: { PsLimitsDetails },
  setup() {
    const geoPsManagementStore = useGeoPsManagementStore();
    const {
      partialUpdateGeoPaymentMethod,
      deleteGeoPaymentMethodById,
      openGeoPaymentMethodSettings,
      checkIsNewlyAddedGeoPaymentMethod,
    } = geoPsManagementStore;
    const {
      geoPaymentMethodList,
      isGeoPaymentMethodListLoading,
      isUnsavedGeoPaymentMethodList,
      isWithdrawColumnsDataVisible,
      isDepositColumnsDataVisible,
    } = storeToRefs(geoPsManagementStore);

    const { psManagementPermissions } = storeToRefs(usePermissionsStore());

    const isUpdateGeoPaymentMethodAllowed = computed(() =>
      psManagementPermissions.value[PS_MANAGEMENT_PERMISSIONS.UPDATE_GEO_PAYMENT_METHOD]);
    const isGetGeoPaymentMethodDepositLimitsAllowed = computed(() =>
      psManagementPermissions.value[PS_MANAGEMENT_PERMISSIONS.GET_GEO_PAYMENT_METHOD_DEPOSIT_LIMITS]);
    const isUpdateGeoPaymentMethodDepositLimitsAllowed = computed(() =>
      psManagementPermissions.value[PS_MANAGEMENT_PERMISSIONS.UPDATE_GEO_PAYMENT_METHOD_DEPOSIT_LIMITS]);
    const isPartialUpdateGlobalPaymentMethodAllowed = computed(() =>
      psManagementPermissions.value[PS_MANAGEMENT_PERMISSIONS.UPDATE_GEO_PAYMENT_METHOD_DEPOSIT_AND_WITHDRAW]);
    const isDeleteGeoPaymentMethodAllowed = computed(() =>
      psManagementPermissions.value[PS_MANAGEMENT_PERMISSIONS.DELETE_GEO_PAYMENT_METHOD]);
    const isGeoPaymentMethodFullListUpdateAllowed = computed(() =>
      psManagementPermissions.value[PS_MANAGEMENT_PERMISSIONS.UPDATE_GEO_PAYMENT_METHOD_LIST]);

    const {
      dragSortingTableRef,
      dragOptions,
      isResortingInProgress,
    } = useDragListSorting();

    const paymentsDepositLimitsStore = usePaymentMethodsDepositLimitsStore();
    const { openPspLimitsSettings } = paymentsDepositLimitsStore;

    const updatePaymentMethodSwitch = (payload: GeoPaymentMethodPartialUpdateParams) => {
      partialUpdateGeoPaymentMethod(payload);
    };

    const initDraggableTableSorting = () => {
      const geoPaymentMethodsTableElement = document.querySelector('.geo-payment-methods tbody') as HTMLElement;

      if (!geoPaymentMethodsTableElement) {
        return;
      }

      Sortable.create(geoPaymentMethodsTableElement, dragOptions);
    };

    onMounted(() => {
      initDraggableTableSorting();
    });

    const requestDeleteConfirmation = (geoPaymentMethod: GeoPaymentMethodItem) => {
      ElMessageBox.confirm(
        'Are you sure want to delete this PS from cashier?',
        'Warning',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          type: 'warning',
        },
      )
        .then(() => {
          deleteGeoPaymentMethodById(geoPaymentMethod);
        });
    };

    const switchPaymentMethod = (
      currentRow: GeoPaymentMethodItem,
      currentColumn: GeoPaymentMethodPartialUpdateParameterKeyModel,
    ) => {
      ElMessageBox.confirm(
        'Are you sure you want to toggle the PS for the cashier?',
        'Warning',
        {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning',
        },
      )
        .then(() => {
          updatePaymentMethodSwitch({
            parameterKey: currentColumn,
            billingPaymentMethodSettingsId: currentRow.billingPaymentMethodSettingsId,
            newValue: currentRow[currentColumn],
          });
        })
        .catch(() => {
          const geoPaymentMethod = geoPaymentMethodList.value
            .find((geoPaymentMethod) =>
              geoPaymentMethod.billingPaymentMethodId === currentRow.billingPaymentMethodId);

          if (!geoPaymentMethod) {
            return;
          }

          geoPaymentMethod[currentColumn] = !currentRow[currentColumn];
        });
    };

    const isActionsColumnVisible = computed(() =>
      isPartialUpdateGlobalPaymentMethodAllowed.value ||
      isUpdateGeoPaymentMethodAllowed.value ||
      isDeleteGeoPaymentMethodAllowed.value);

    const isAllowedSettingButton = (row: GeoPaymentMethodItem): boolean => {
      return (isUpdateGeoPaymentMethodAllowed.value ||
          isUpdateGeoPaymentMethodDepositLimitsAllowed.value) &&
          Boolean(row.billingPaymentMethodSettingsId);
    };

    return {
      icons,
      isGeoPaymentMethodListLoading,
      geoPaymentMethodList,
      geoPaymentMethodColumnsList,
      GeoPaymentMethodColumnProperties,
      switchPaymentMethod,
      dragSortingTableRef,
      isResortingInProgress,
      openGeoPaymentMethodSettings,
      openPspLimitsSettings,
      deleteGeoPaymentMethodById,
      requestDeleteConfirmation,
      isDepositColumnsDataVisible,
      isWithdrawColumnsDataVisible,
      GeoPaymentMethodOperation,
      checkIsNewlyAddedGeoPaymentMethod,
      isUnsavedGeoPaymentMethodList,
      isUpdateGeoPaymentMethodAllowed,
      isUpdateGeoPaymentMethodDepositLimitsAllowed,
      isDeleteGeoPaymentMethodAllowed,
      isPartialUpdateGlobalPaymentMethodAllowed,
      isGeoPaymentMethodFullListUpdateAllowed,
      isActionsColumnVisible,
      isGetGeoPaymentMethodDepositLimitsAllowed,
      isAllowedSettingButton,
    };
  },
});
