export type TransactionListFilterModel = {
  operation?: string[];
  country?: string[];
  currency?: string[];
  transactionStatus?: string[];
  moderationStatus?: string[];
  psp?: string[];
  bpId?: string[];
  paymentSystem?: string[];
  os?: string[];
  updatedAtFrom?: number;
  updatedAtTo?: number;
  createdAtFrom?: number;
  createdAtTo?: number;
  cancelledBy?: string;
  processingType?: string;
  fromAmountEur?: number;
  toAmountEur?: number;
  firstDeposit?: string;
  state?: string;
  email?: string;
  affiliateId?: string;
  username?: string;
  accountHolderName?: string;
  transactionId?: string;
  paymentAccount?: string;
  backOfficeAdminEmail?: string;
}

export type TransactionFilterOptionsItem = {
  text: string;
  value: string | number;
}

export type TransactionFilterKey = 'bpId' | 'paymentSystem' | 'psp';

export type TransactionFilterHandlers = Record<TransactionFilterKey, () => void>;

export const cancelledByFilterOptions: TransactionFilterOptionsItem[] = [
  { text: 'Player', value: 'player' },
  { text: 'Agent', value: 'agent' },
  { text: 'PSP', value: 'psp' },
  { text: 'KYC', value: 'kyc' },
];

export const transactionStatusFilterOptions: TransactionFilterOptionsItem[] = [
  { text: 'Pending', value: 'pending' },
  { text: 'Hold', value: 'hold' },
  { text: 'Processed', value: 'processed' },
  { text: 'Cancelled', value: 'cancelled' },
  { text: 'Manual cancel', value: 'manualCancel' },
  { text: 'Paid', value: 'paid' },
  { text: 'Manual paid', value: 'manualPaid' },
  { text: 'Initiated', value: 'initiated' },
  { text: 'Uncompleted', value: 'uncompleted' },
  { text: 'Waiting KYC result', value: 'kycVerification' },
];

export const moderationStatusFilterOptions: TransactionFilterOptionsItem[] = [
  { text: 'Wait', value: 'wait' },
  { text: 'Moderation', value: 'moderation' },
  { text: 'Done', value: 'done' },
  { text: 'Hold', value: 'hold' },
  { text: 'Cancel', value: 'cancel' },
];

export const processingTypeFilterOptions: TransactionFilterOptionsItem[] = [
  { text: 'Auto', value: 'auto' },
  { text: 'Manual', value: 'manual' },
];

export const firstDepositFilterOptions: TransactionFilterOptionsItem[] = [
  { text: 'True', value: 'true' },
  { text: 'False', value: 'false' },
];
