<template>
  <el-container class="psp-sorting-page">
    <el-header class="psp-sorting-page__header">
      <h2 class="psp-sorting-page__header-title">
        <img
          v-if="!isOnlyOneWhitelabelAvailable"
          :src="iconPageMultibrand"
          class="psp-sorting-page__header-icon"
        >
        PSP Sorting
      </h2>
      <div class="psp-sorting-page__actions">
        <div class="psp-sorting-page__brand-select_wrapper">
          <el-select
            v-if="isBrandFilterVisible"
            v-model="pspSortingFilterFormData.brand"
            :loading="isPspSortingListLoading"
            placeholder="Select brand"
            filterable
            multiple
            clearable
            :collapse-tags="2"
            class="psp-sorting-page__select-field"
          >
            <el-option
              v-for="brand in brandFilters"
              :key="brand.value"
              :value="brand.value"
              :label="brand.label"
            >
              {{ brand.label }}
            </el-option>
          </el-select>
        </div>
        <div class="psp-sorting-page__country-select_wrapper">
          <el-select
            v-model="pspSortingFilterFormData.country"
            :loading="isCountrySelectLoading"
            placeholder="Select country"
            filterable
            clearable
            value-key="code"
            class="psp-sorting-page__select-field"
            @clear="resetPspSortingList"
          >
            <el-option
              v-for="country in psManagementCountryList"
              :key="country.code"
              :value="country.code"
              :label="country.name"
            >
              {{ country.name }}
            </el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          :disabled="!isCountrySelected"
          @click="getPspSortingList"
        >
          Get PSP Sorting
        </el-button>
      </div>
    </el-header>
  </el-container>
  <el-table
    v-loading="isPspSortingListLoading"
    :data="extendedPspSortingList"
    size="small"
    border
    class="psp-sorting-page__table"
    tooltip-effect="light"
    :span-method="pspSortingTableMergeCells"
    row-class-name="psp-sorting-page__table-row"
  >
    <template #empty>
      <el-empty>
        <template #description>
          <p class="psp-sorting-page__table-empty-text">
            No data fetched yet
          </p>
          <p class="psp-sorting-page__table-empty-text">
            Select country and click Get PSP Sorting
          </p>
        </template>
      </el-empty>
    </template>
    <el-table-column
      prop="brandName"
      label="Brand name"
      align="top"
    >
      <template #default="{ row }">
        <div class="psp-sorting-page__table-sticky-cell">
          {{ row.brandName }}
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="lastUpdated" label="Last updated" width="130" />
    <el-table-column prop="paymentGateway" label="Payment provider" />
    <el-table-column prop="paymentSystem" label="Payment method" />
    <el-table-column label="Logo" width="70">
      <template #default="{ row }">
        <el-icon v-if="!row.imageUrl" class="psp-sorting-page__table-image">
          <component :is="icons.Picture" />
        </el-icon>
        <el-image v-else :src="row.imageUrl" class="psp-sorting-page__table-image" />
      </template>
    </el-table-column>
  </el-table>
</template>

<script src="./psp-sorting-page.ts" />
<style src="./psp-sorting-page.scss" />
