import { api } from '@/services/http.service';
import { defineStore } from 'pinia';
import { ElNotification, FormInstance } from 'element-plus';
import { DEFAULT_OFFSET, DEFAULT_PAYMENT_METHODS_LIST_LIMIT } from '@/models/constants';
import {
  DEFAULT_GEO_PAYMENT_METHOD_FORM,
  GeoPaymentMethodItem,
  GeoPaymentMethodOperation,
  GeoPaymentMethodPartialUpdateParams,
} from '@/models/ps-management/ps-management.model';
import { usePsManagementCountryListStore } from '@/store/ps-management/payment-methods-country-list.store';
import { useDragListSorting } from '@/components/drag-sorting/drag-sorting.hook';
import { cloneDeep, remove } from 'lodash';
import { useUploadImageStore } from '@/store/upload-image.store';
import { ref } from 'vue';
import { CurrencyItem } from '@/models/dictionary/currency-list.model';
import { useCurrencyListStore } from '../dictionary/currency-list.store';
import { TagListItemResponseModel, TagListItem } from '@/models/dictionary/tag-list.model';

interface GeoPsManagementStore {
  geoPaymentMethodList: GeoPaymentMethodItem[];
  isGeoPaymentMethodListLoading: boolean;
  limit: number;
  offset: number;
  geoCurrentOperation: GeoPaymentMethodOperation;
  isGeoPaymentMethodLoading: boolean;
  geoPaymentMethod: GeoPaymentMethodItem | {};
  isAddGeoPaymentMethodsDialogVisible: boolean;
  geoPaymentMethodsSettingsFormData: GeoPaymentMethodItem;
  isGeoPaymentMethodSettingsSidebarVisible: boolean;
}

export const geoPaymentMethodsSettingsFormRef = ref<FormInstance>();

export const useGeoPsManagementStore = defineStore({
  id: 'GeoPsManagementStore',
  state: (): GeoPsManagementStore => ({
    geoPaymentMethodList: [],
    isGeoPaymentMethodListLoading: false,
    limit: DEFAULT_PAYMENT_METHODS_LIST_LIMIT,
    offset: DEFAULT_OFFSET,
    geoCurrentOperation: GeoPaymentMethodOperation.DEPOSIT,
    isGeoPaymentMethodLoading: false,
    geoPaymentMethod: {},
    isAddGeoPaymentMethodsDialogVisible: false,
    geoPaymentMethodsSettingsFormData: cloneDeep(DEFAULT_GEO_PAYMENT_METHOD_FORM),
    isGeoPaymentMethodSettingsSidebarVisible: false,
  }),
  getters: {
    isUnsavedGeoPaymentMethodList(state): boolean {
      return state.geoPaymentMethodList.some(geoPaymentMethod => !geoPaymentMethod.billingPaymentMethodSettingsId);
    },
    isDepositColumnsDataVisible(state): boolean {
      return state.geoCurrentOperation === GeoPaymentMethodOperation.DEPOSIT || !state.geoCurrentOperation;
    },
    isWithdrawColumnsDataVisible(state): boolean {
      return state.geoCurrentOperation === GeoPaymentMethodOperation.WITHDRAW || !state.geoCurrentOperation;
    },
    availableDepositCurrencyList(state): CurrencyItem[] {
      const { currencyList } = useCurrencyListStore();

      const selectedCurrencyList = state
        .geoPaymentMethodsSettingsFormData
        .depositLimitList
        .map(currency => currency.currency);

      return currencyList.filter(currency => !selectedCurrencyList.includes(currency.code));
    },
    availableCashoutCurrencyList(state): CurrencyItem[] {
      const { currencyList } = useCurrencyListStore();

      const selectedCurrencyList = state
        .geoPaymentMethodsSettingsFormData
        .withdrawLimitList
        .map(currency => currency.currency);

      return currencyList.filter(currency => !selectedCurrencyList.includes(currency.code));
    },
  },
  actions: {
    setDefaultGeoPaymentMethodsSettingsFormData(): void {
      this.geoPaymentMethodsSettingsFormData = cloneDeep(DEFAULT_GEO_PAYMENT_METHOD_FORM);
    },
    setGeoPaymentMethodSettingsSidebarVisible(isVisible: boolean): void {
      this.isGeoPaymentMethodSettingsSidebarVisible = isVisible;
    },
    setAddGeoPaymentMethodsDialogVisibility(isVisible: boolean): void {
      this.isAddGeoPaymentMethodsDialogVisible = isVisible;
    },
    openAddGeoPaymentMethodsDialog(): void {
      this.setAddGeoPaymentMethodsDialogVisibility(true);
    },
    closeGeoPaymentMethodsAddingDialog(): void {
      this.setAddGeoPaymentMethodsDialogVisibility(false);
    },
    setGeoPaymentMethodListLoading(isLoading: boolean): void {
      this.isGeoPaymentMethodListLoading = isLoading;
    },
    setGeoPaymentMethodLoading(isLoading: boolean): void {
      this.isGeoPaymentMethodLoading = isLoading;
    },
    checkIsNewlyAddedGeoPaymentMethod(geoPaymentMethod: GeoPaymentMethodItem): boolean {
      return !geoPaymentMethod.billingPaymentMethodSettingsId;
    },
    setOffset(offset: number): void {
      this.offset = offset;
    },
    setLimit(limit: number): void {
      this.limit = limit;
    },
    getGeoPaymentMethodList(): Promise<GeoPaymentMethodItem[]> {
      const { selectedCountry } = usePsManagementCountryListStore();
      const selectedCountryCode = selectedCountry?.code;
      if (!selectedCountryCode) {
        return Promise.resolve([]);
      }

      this.setGeoPaymentMethodListLoading(true);

      const requestPayload = {
        limit: this.limit,
        offset: this.offset,
        filterList: {
          country: selectedCountryCode,
          operation: this.geoCurrentOperation,
        },
      };

      return api.psManagement.getGeoPaymentMethodListRequest
        .perform(requestPayload)
        .then(response => {
          const { isResortingInProgress } = useDragListSorting();
          isResortingInProgress.value = false;

          this.geoPaymentMethodList = response;

          return response;
        }).catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });

          return ([]);
        }).finally(() => {
          const { isResortingInProgress } = useDragListSorting();
          isResortingInProgress.value = false;
          this.setGeoPaymentMethodListLoading(false);
        });
    },
    updateGeoPaymentMethod(geoPaymentMethodData: GeoPaymentMethodItem): Promise<void> {
      const { resetImageData } = useUploadImageStore();

      this.setGeoPaymentMethodLoading(true);

      const transformTagList = (tagList?: TagListItemResponseModel[]): TagListItem[] | [] =>
        tagList ? tagList.map(({ tagId, name }: TagListItem) => ({ tagId, name })) : [];

      const paymentMethodData: GeoPaymentMethodItem = {
        ...geoPaymentMethodData,
        excludedTagList: transformTagList(geoPaymentMethodData.excludedTagList as TagListItemResponseModel[]),
        tagList: transformTagList(geoPaymentMethodData.tagList as TagListItemResponseModel[]),
      };

      return api.psManagement.updateGeoPaymentMethodRequest
        .perform({
          billingPaymentMethodSettingsId: geoPaymentMethodData.billingPaymentMethodSettingsId,
          paymentMethodData,
        })
        .then(() => {
          ElNotification({
            title: 'Success',
            message: 'Payment method updated',
            type: 'success',
          });
          resetImageData();
          this.closeGeoPaymentMethodSettings();
          this.getGeoPaymentMethodList();
        })
        .catch(error => {
          ElNotification({
            title: 'Error',
            message: error.response.data.message,
            type: 'error',
          });
        })
        .finally(() => {
          this.setGeoPaymentMethodLoading(false);
        });
    },
    partialUpdateGeoPaymentMethod(payload: GeoPaymentMethodPartialUpdateParams): Promise<void> {
      this.setGeoPaymentMethodListLoading(true);

      const { parameterKey, billingPaymentMethodSettingsId, newValue } = payload;

      const partialUpdatePayload = {
        billingPaymentMethodSettingsId,
        data: {
          [parameterKey]: newValue,
        },
      };

      return api.psManagement.partialUpdateGeoPaymentMethodRequest
        .perform(partialUpdatePayload)
        .then(() => {
          ElNotification({
            title: 'Success',
            message: 'Payment method updated',
            type: 'success',
          });
        }).finally(() => {
          this.getGeoPaymentMethodList();
          this.setGeoPaymentMethodListLoading(false);
        });
    },
    updateFullGeoPaymentMethodList(): Promise<void> {
      this.setGeoPaymentMethodListLoading(true);

      const { selectedCountry } = usePsManagementCountryListStore();

      const paymentMethodsListLength = this.geoPaymentMethodList.length;

      const updatedSortingWeightPayload = this.geoPaymentMethodList.map((geoPaymentMethod, index) => ({
        ...geoPaymentMethod,
        sortingWeight: paymentMethodsListLength - index - 1,
        country: selectedCountry?.code,
        operation: this.geoCurrentOperation,
      }));

      return api.psManagement.updateFullGeoPaymentMethodListRequest
        .perform({
          country: selectedCountry?.code as string,
          operation: this.geoCurrentOperation,
          paymentMethodSettingsList: updatedSortingWeightPayload,
        })
        .then(() => {
          this.getGeoPaymentMethodList();

          ElNotification({
            title: 'Success',
            message: 'Payment methods list sorting updated',
            type: 'success',
          });
        })
        .finally(() => {
          const { isResortingInProgress } = useDragListSorting();
          isResortingInProgress.value = false;

          this.setGeoPaymentMethodListLoading(false);
        });
    },
    getGeoPaymentMethod(billingPaymentMethodSettingsId: string): Promise<GeoPaymentMethodItem | void> {
      this.setGeoPaymentMethodLoading(true);

      return api.psManagement.getGeoPaymentMethodRequest
        .perform(billingPaymentMethodSettingsId)
        .then((response: GeoPaymentMethodItem) => {
          this.geoPaymentMethod = response;
        })
        .catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });
        })
        .finally(() => {
          this.setGeoPaymentMethodLoading(false);
        });
    },
    deleteGeoPaymentMethodById(geoPaymentMethodItem: GeoPaymentMethodItem): Promise<void> {
      if (this.checkIsNewlyAddedGeoPaymentMethod(geoPaymentMethodItem)) {
        remove(this.geoPaymentMethodList, geoPaymentMethodItem);

        ElNotification({
          title: 'Success',
          message: 'Payment method deleted successfully',
          type: 'success',
        });

        return Promise.resolve();
      }

      this.setGeoPaymentMethodListLoading(true);

      return api.psManagement.deleteGeoPaymentMethodRequest
        .perform(geoPaymentMethodItem.billingPaymentMethodSettingsId)
        .then(() => {
          ElNotification({
            title: 'Success',
            message: 'Payment method deleted successfully',
            type: 'success',
          });

          this.getGeoPaymentMethodList();
        })
        .catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });
        });
    },
    openGeoPaymentMethodSettings(billingPaymentMethodSettingsId: string) {
      const { getFileUrl, setOldImageUrl } = useUploadImageStore();

      this.setGeoPaymentMethodSettingsSidebarVisible(true);

      this.getGeoPaymentMethod(billingPaymentMethodSettingsId).then(() => {
        this.geoPaymentMethodsSettingsFormData = cloneDeep(this.geoPaymentMethod) as GeoPaymentMethodItem;

        if (this.geoPaymentMethodsSettingsFormData.imageId) {
          getFileUrl(this.geoPaymentMethodsSettingsFormData.imageId)
            .then((url) => {
              setOldImageUrl(url);
            });
        }
      });
    },
    closeGeoPaymentMethodSettings(): void {
      const { resetImageData } = useUploadImageStore();

      resetImageData();
      this.setDefaultGeoPaymentMethodsSettingsFormData();
      this.setGeoPaymentMethodSettingsSidebarVisible(false);
    },
  },
});
