import { storeToRefs } from 'pinia';
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import { Filter, Refresh, InfoFilled, Download } from '@element-plus/icons-vue';
import ColumnFilter from '@/components/column-filter/column-filter';
import {
  transactionListColumns,
  transactionListColumnsStorageKey,
  TransactionListColumnProperties,
  paymentStatusTagTypes,
  PaymentStatuses,
  operationStatusTagTypes,
} from '@/models/transaction/transaction-list.model';
import { transactionListSummaryColumns } from '@/models/transaction/transaction-list-summary.model';
import { cloneDeep } from 'lodash';
import useEmitter from '@/hooks/use-emitter';
import { ColumnFilterItem } from '@/models/column-filter/column-filter.model';
import { getSortValue } from '@/utils/set-sort-string';
import { SortingMapping, TagComponentTypes } from '@/models/app-common.model';
import TokenPagination from '@/components/token-pagination/token-pagination';
import useTokenPagination from '@/hooks/use-token-pagination.hook';
import { TRANSACTION_ITEMS_PER_PAGE_LIST } from '@/models/constants';
import { useTransactionListStore } from '@/store/transaction/transaction-list.store';
import { useTransactionListSummaryStore } from '@/store/transaction/transaction-list-summary.store';
import { useTransactionListFilterStore } from '@/store/transaction/transaction-list-filter.store';
import { useTransactionListReportStore } from '@/store/transaction/transaction-list-report.store';
import { usePermissionsStore } from '@/store/permissions.store';
import { useTwoFactorStore } from '@/store/two-factor-dialog.store';
import ListFilterSidebar from '@/components/list-filter-sidebar/list-filter-sidebar';
import TransactionsFilter from '@/layouts/transactions-page/transactions-filter/transactions-filter.vue';
import TwoFactorAuthDialog from '@/components/two-factor-auth-dialog/two-factor-auth-dialog.vue';

const icons = {
  Refresh,
  Filter,
  InfoFilled,
  Download,
};

export default defineComponent({
  name: 'TransactionsPage',
  components: {
    ColumnFilter,
    TokenPagination,
    ListFilterSidebar,
    TransactionsFilter,
    TwoFactorAuthDialog,
  },
  setup() {
    const transactionListStore = useTransactionListStore();
    const {
      getTransactionList,
      setTransactionListLoading,
      setSort,
      setLimit,
    } = transactionListStore;
    const {
      transactionList,
      transactionListWithFormattedDates,
      isTransactionListLoading,
    } = storeToRefs(transactionListStore);

    const transactionListFilterStore = useTransactionListFilterStore();
    const {
      openFilter,
      closeFilter,
      submitFilter,
      resetFilter,
    } = transactionListFilterStore;
    const { isFilterActive, isFilterVisible, activeFiltersCount } = storeToRefs(transactionListFilterStore);

    const transactionListSummaryStore = useTransactionListSummaryStore();
    const { getTransactionListSummary } = transactionListSummaryStore;
    const { transactionListSummaryData, isTransactionListSummaryLoading } = storeToRefs(transactionListSummaryStore);

    const transactionListReportStore = useTransactionListReportStore();
    const { getTransactionListReport } = transactionListReportStore;
    const { isTransactionListReportLoading } = storeToRefs(transactionListReportStore);

    const twoFactorStore = useTwoFactorStore();
    const { openTwoFactorDialog } = twoFactorStore;

    const { isTransactionsDownloadCvsAllowed } = storeToRefs(usePermissionsStore());

    const originalColumns = ref(transactionListColumns);
    const storageData = computed(() => localStorage.getItem(transactionListColumnsStorageKey));
    const setColumnsFromStorage = (): void => {
      if (storageData.value) {
        originalColumns.value = JSON.parse(storageData.value);
      }
    };

    const { updatePagination, resetPaginationTokens } = useTokenPagination();

    const { openPlayerProfile } = useEmitter();

    watch(() => localStorage.getItem(transactionListColumnsStorageKey), () => {
      setColumnsFromStorage();
    });

    const updateColumns = (columns: ColumnFilterItem[]): void => {
      setTransactionListLoading(true);
      setTimeout(() => {
        originalColumns.value = cloneDeep(columns);

        setTransactionListLoading(false);
      }, 1000);
    };

    const resetColumns = (): void => {
      setTransactionListLoading(true);
      setTimeout(() => {
        originalColumns.value = cloneDeep(transactionListColumns);

        setTransactionListLoading(false);
      }, 1000);
    };

    const refreshData = () => {
      resetPaginationTokens();
      getTransactionLists();
    };

    const changeItemsPerPage = (limit: number): void => {
      setLimit(limit);
      updatePagination({});
    };

    const getTransactionLists = (): void => {
      getTransactionList();
      getTransactionListSummary();
    };

    const changeColumnSort = ({ prop, order }: { prop: string; order: string }): void => {
      setSort(getSortValue({ columnName: prop, order: SortingMapping[order] }));
      updatePagination({});
      getTransactionLists();
    };

    const getOperationStatusTagType = (operation: string): string => {
      if (!operationStatusTagTypes[operation]) {
        return TagComponentTypes.INFO;
      }

      return operationStatusTagTypes[operation];
    };

    const isTransactionListsLoading = computed((): boolean => {
      return isTransactionListLoading.value || isTransactionListSummaryLoading.value;
    });

    onMounted(() => {
      setColumnsFromStorage();
      getTransactionLists();
    });

    onUnmounted(() => {
      resetPaginationTokens();
    });

    return {
      icons,
      transactionList,
      transactionListWithFormattedDates,
      isTransactionListLoading,
      transactionListColumnsStorageKey,
      originalColumns,
      updateColumns,
      resetColumns,
      changeColumnSort,
      getOperationStatusTagType,
      changeItemsPerPage,
      openPlayerProfile,
      TransactionListColumnProperties,
      PaymentStatuses,
      paymentStatusTagTypes,
      isFilterActive,
      isFilterVisible,
      openFilter,
      closeFilter,
      submitFilter,
      resetFilter,
      activeFiltersCount,
      transactionListSummaryData,
      isTransactionListSummaryLoading,
      transactionListSummaryColumns,
      getTransactionLists,
      isTransactionListsLoading,
      isTransactionListReportLoading,
      openTwoFactorDialog,
      getTransactionListReport,
      isTransactionsDownloadCvsAllowed,
      refreshData,
      TRANSACTION_ITEMS_PER_PAGE_LIST,
    };
  },
});
