import { defineComponent, computed, ref, reactive, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { usePresetSettingsStore } from '@/store/preset-settings/preset-settings.store';
import { useCountryListStore } from '@/store/dictionary/country-list.store';
import { useCurrencyListStore } from '@/store/dictionary/currency-list.store';
import { Plus, ArrowRight, Delete } from '@element-plus/icons-vue';
import { PresetSettingsButtonName } from '@/models/preset-settings/preset-settings.model';
import type { CurrencyItem } from '@/models/dictionary/currency-list.model';
import { type FormRules, ElNotification, ElMessageBox } from 'element-plus';
import {
  type CurrencyFormValue,
  type SettingsForm,
  initSettingsFormSwitchers,
  initSettingsForm,
  changeableFields,
} from '@/models/preset-settings/settings-screen.model';
import { presetSettingsValidationRules } from '@/hooks/preset-settings/preset-settings-validation-rules';
import { getMappedFormDataFromConfig, getMappedPayload } from '@/utils/preset-settings';
import AddCurrencyDialog from '@/layouts/preset-settings-page/modals/add-currency-dialog/add-currency-dialog.vue';
import CurrencyForm from '@/layouts/preset-settings-page/currency-form/currency-form.vue';

const icons = {
  Plus,
  ArrowRight,
  Delete,
};

export default defineComponent({
  name: 'SettingsScreen',
  components: {
    AddCurrencyDialog,
    CurrencyForm,
  },
  setup() {
    const presetSettingsStore = usePresetSettingsStore();
    const {
      selectedCountryConfig,
      isCashierConfigListLoading,
      isCurrencyFormChanged,
    } = storeToRefs(presetSettingsStore);
    const {
      setSelectedCountry,
      getCashierConfigList,
      updateCashierConfig,
      getCurrencyListFromConfig,
      getCashierConfig,
      setIsCurrencyFormChanged,
    } = presetSettingsStore;

    const { countryList } = storeToRefs(useCountryListStore());
    const { currencyList } = storeToRefs(useCurrencyListStore());

    const isAddCurrencyDialogVisible = ref(false);
    const currentCountryCurrencyList = ref<string[]>([]);
    const currencyFormList = ref<Record<string, CurrencyFormValue>>({});

    const currencyFormListRules = reactive<FormRules<SettingsForm>>(
      Object.keys(initSettingsForm).reduce((acc, field) => {
        if (changeableFields.includes(field)) {
          return acc;
        }

        return {
          ...acc,
          [field]: [presetSettingsValidationRules.commonFieldRule],
        };
      }, {}),
    );

    const openAddCurrencyDialog = (): void => {
      isAddCurrencyDialogVisible.value = true;
    };

    const selectedCountryName = computed<string>(() => {
      return countryList.value
        .find(country => selectedCountryConfig.value.country === country.code)?.name ?? '';
    });

    const isSaveButtonDisabled = computed<boolean>(() => !Object.keys(currencyFormList.value).length);

    const availableCurrencyList = computed<CurrencyItem[]>(() => currencyList.value
      .filter((currency: CurrencyItem) => !currentCountryCurrencyList.value.includes(currency.code)));

    const closeSettingsScreen = (): void => {
      if (!isCurrencyFormChanged.value) {
        setSelectedCountry('');
        getCashierConfigList();

        return;
      }

      ElMessageBox.confirm(
        `Are you sure you want to close ${selectedCountryName.value} settings?
        If you made any changes, they will be lost without saving`,
        `Close ${selectedCountryName.value} settings?`,
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Close settings',
          cancelButtonText: 'Cancel',
          center: true,
          type: 'warning',
        },
      ).then(() => {
        setSelectedCountry('');
        getCashierConfigList();
        setIsCurrencyFormChanged(false);
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      }).catch(() => {});
    };

    const addCurrencies = (currencyList: string[]): void => {
      currencyList.forEach((currency) => {
        currencyFormList.value[currency] = {
          customRef: null,
          switchers: { ...initSettingsFormSwitchers },
          data: { ...initSettingsForm },
        };

        currentCountryCurrencyList.value.push(currency);
      });
    };

    const deleteCurrencyForm = (currency: string): void => {
      ElMessageBox.confirm(
        `Are you sure you want to delete ${currency} currency? All data will be lost`,
        `Delete ${currency}?`,
        {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          center: true,
          type: 'error',
        },
      ).then(() => {
        const index = currentCountryCurrencyList.value.findIndex(currencyFromList => currencyFromList === currency);
        currentCountryCurrencyList.value.splice(index, 1);

        Reflect.deleteProperty(currencyFormList.value, currency);
        setIsCurrencyFormChanged(true);
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      }).catch(() => {});
    };

    const submitForm = async (): Promise<void> => {
      const allFormsValidFlags: boolean[] = [];

      for (let i = 0; i < currentCountryCurrencyList.value.length; i++) {
        const currency = currentCountryCurrencyList.value[i];
        const formEl = currencyFormList.value[currency]?.customRef;

        if (!formEl) continue;

        changeableFields.forEach((key) => {
          if (!formEl?.switchers?.[key]) {
            currencyFormList.value[currency].data[key] = '';
          }
        });

        try {
          const valid = await formEl.currencyFormRef?.validate();
          allFormsValidFlags.push(valid);

          continue;
        } catch (error) {
          allFormsValidFlags.push(false);

          ElNotification({
            title: 'Validation Error',
            message: `Validation failed for ${currency}.`,
            type: 'error',
          });

          continue;
        }
      }

      if (allFormsValidFlags.every(Boolean)) {
        setIsCurrencyFormChanged(false);

        const payload = getMappedPayload(currencyFormList.value, selectedCountryConfig.value.country);
        updateCashierConfig(payload, selectedCountryName.value);
      }
    };

    const initData = (): void => {
      currentCountryCurrencyList.value =
      getCurrencyListFromConfig(selectedCountryConfig.value.config);

      const formDataFromConfig = getMappedFormDataFromConfig(selectedCountryConfig.value.config);

      currencyFormList.value = currentCountryCurrencyList.value.reduce((acc, currency) => {
        const switchers = { ...initSettingsFormSwitchers };
        changeableFields.forEach(field => {
          switchers[field] = Boolean(formDataFromConfig?.[currency][field]);
        });

        return {
          ...acc,
          [currency]: {
            customRef: null,
            switchers,
            data: formDataFromConfig?.[currency] ?? { ...initSettingsForm },
          },
        };
      }, {});
    };

    onMounted(() => {
      getCashierConfig(selectedCountryConfig.value.country)
        .then(initData);
    });

    return {
      isCurrencyFormChanged,
      icons,
      submitForm,
      addCurrencies,
      currencyFormList,
      deleteCurrencyForm,
      closeSettingsScreen,
      selectedCountryName,
      isSaveButtonDisabled,
      availableCurrencyList,
      openAddCurrencyDialog,
      currencyFormListRules,
      PresetSettingsButtonName,
      isAddCurrencyDialogVisible,
      currentCountryCurrencyList,
      isCashierConfigListLoading,
      setIsCurrencyFormChanged,
    };
  },
});
