import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "transactions-filter__tooltip-content" }
const _hoisted_2 = { class: "transactions-filter__tooltip-content" }
const _hoisted_3 = { class: "transactions-filter__tooltip-content" }
const _hoisted_4 = { class: "transactions-filter__tooltip-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "transactionsFilterFormRef",
    "label-position": "top",
    class: "transactions-filter"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        label: "From updated date",
        prop: "updatedAtFrom",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tooltip, {
            content: "Filter on the updated date",
            placement: "bottom"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.filterFormData.updatedAtFrom,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterFormData.updatedAtFrom) = $event)),
                  type: "datetime",
                  placeholder: "Select date and time"
                }, null, 8 /* PROPS */, ["modelValue"])
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "To updated date",
        prop: "updatedAtTo",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tooltip, {
            content: "Filter on the updated date",
            placement: "bottom"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.filterFormData.updatedAtTo,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterFormData.updatedAtTo) = $event)),
                  type: "datetime",
                  placeholder: "Select date and time"
                }, null, 8 /* PROPS */, ["modelValue"])
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "From created date",
        prop: "createdAtFrom",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tooltip, {
            content: "Filter on the created date",
            placement: "bottom"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.filterFormData.createdAtFrom,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filterFormData.createdAtFrom) = $event)),
                  type: "datetime",
                  placeholder: "Select date and time"
                }, null, 8 /* PROPS */, ["modelValue"])
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "To created date",
        prop: "createdAtTo",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_tooltip, {
            content: "Filter on the created date",
            placement: "bottom"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.filterFormData.createdAtTo,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filterFormData.createdAtTo) = $event)),
                  type: "datetime",
                  placeholder: "Select date and time"
                }, null, 8 /* PROPS */, ["modelValue"])
              ])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Operation",
        prop: "operation",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.filterFormData.operation,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filterFormData.operation) = $event)),
            loading: _ctx.isPaymentOperationListLoading,
            multiple: "",
            placeholder: "Select payment operations",
            filterable: "",
            clearable: "",
            "fit-input-width": ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentOperationList, (paymentOperation) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: paymentOperation.value,
                  label: paymentOperation.label,
                  value: paymentOperation.value
                }, null, 8 /* PROPS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "loading"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Country",
        prop: "country",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.filterFormData.country,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filterFormData.country) = $event)),
            loading: _ctx.isCountryListLoading,
            multiple: "",
            placeholder: "Select countries",
            filterable: "",
            clearable: "",
            "fit-input-width": ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countryList, (country) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: country.code,
                  label: country.name,
                  value: country.code
                }, null, 8 /* PROPS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "loading"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Currency",
        prop: "currency",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.filterFormData.currency,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.filterFormData.currency) = $event)),
            loading: _ctx.isCurrencyListLoading,
            multiple: "",
            placeholder: "Select currencies",
            filterable: "",
            clearable: "",
            "fit-input-width": ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currencyList, (currency) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: currency.code,
                  label: currency.code,
                  value: currency.code
                }, null, 8 /* PROPS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "loading"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Cancelled by",
        prop: "cancelledBy",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.filterFormData.cancelledBy,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.filterFormData.cancelledBy) = $event)),
            multiple: false,
            placeholder: "Select",
            filterable: "",
            clearable: "",
            "fit-input-width": ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cancelledByFilterOptions, (cancelledByOption) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: cancelledByOption.value,
                  label: cancelledByOption.text,
                  value: cancelledByOption.value
                }, null, 8 /* PROPS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Status",
        prop: "transactionStatus",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.filterFormData.transactionStatus,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.filterFormData.transactionStatus) = $event)),
            multiple: "",
            placeholder: "Select transaction statuses",
            filterable: "",
            clearable: "",
            "fit-input-width": ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transactionStatusFilterOptions, (transactionStatus) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: transactionStatus.value,
                  label: transactionStatus.text,
                  value: transactionStatus.value
                }, null, 8 /* PROPS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Moderation status",
        prop: "moderationStatus",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.filterFormData.moderationStatus,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.filterFormData.moderationStatus) = $event)),
            multiple: "",
            placeholder: "Select moderation statuses",
            filterable: "",
            clearable: "",
            "fit-input-width": ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moderationStatusFilterOptions, (moderationStatus) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: moderationStatus.value,
                  label: moderationStatus.text,
                  value: moderationStatus.value
                }, null, 8 /* PROPS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Processing type",
        prop: "processingType",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.filterFormData.processingType,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.filterFormData.processingType) = $event)),
            multiple: false,
            placeholder: "Select processing type",
            filterable: "",
            clearable: "",
            "fit-input-width": ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.processingTypeFilterOptions, (processingType) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: processingType.value,
                  label: processingType.text,
                  value: processingType.value
                }, null, 8 /* PROPS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "PSP",
        prop: "psp",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.filterFormData.psp,
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.filterFormData.psp) = $event)),
            loading: _ctx.isPaymentListLoading,
            multiple: "",
            placeholder: "Select PSP",
            filterable: "",
            clearable: "",
            "fit-input-width": "",
            onChange: _cache[12] || (_cache[12] = ($event: any) => (_ctx.changeFilter('psp')))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availablePaymentList, (pspItem) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: pspItem.psp,
                  label: pspItem.psp,
                  value: pspItem.psp
                }, null, 8 /* PROPS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "loading"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Billing provider",
        prop: "bpId",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.filterFormData.bpId,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.filterFormData.bpId) = $event)),
            loading: _ctx.isBillingProviderListLoading,
            multiple: "",
            placeholder: "Select billing providers",
            filterable: "",
            clearable: "",
            "fit-input-width": "",
            onChange: _cache[14] || (_cache[14] = ($event: any) => (_ctx.changeFilter('bpId')))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableBillingProviderList, (billingProvider) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: billingProvider.bpId,
                  label: billingProvider.bpId,
                  value: billingProvider.bpId
                }, null, 8 /* PROPS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "loading"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Payment system",
        prop: "paymentSystem",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.filterFormData.paymentSystem,
            "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.filterFormData.paymentSystem) = $event)),
            loading: _ctx.isPaymentSystemListLoading,
            multiple: "",
            placeholder: "Select payment systems",
            filterable: "",
            clearable: "",
            "fit-input-width": "",
            onChange: _cache[16] || (_cache[16] = ($event: any) => (_ctx.changeFilter('paymentSystem')))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availablePaymentSystemList, (paymentSystem) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: paymentSystem.paymentSystem,
                  value: paymentSystem.paymentSystem,
                  class: "transactions-filter__option"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_tooltip, {
                      effect: "light",
                      content: paymentSystem.paymentSystem,
                      placement: "top",
                      "popper-class": "transactions-filter__option-tooltip",
                      teleported: true
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(paymentSystem.paymentSystem), 1 /* TEXT */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["content"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "loading"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "From amount EUR",
        prop: "fromAmountEur",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.filterFormData.fromAmountEur,
            "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.filterFormData.fromAmountEur) = $event)),
            placeholder: "Input amount",
            clearable: ""
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "To amount EUR",
        prop: "toAmountEur",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.filterFormData.toAmountEur,
            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.filterFormData.toAmountEur) = $event)),
            placeholder: "Input amount",
            clearable: ""
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "First attempt",
        prop: "firstDeposit",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.filterFormData.firstDeposit,
            "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.filterFormData.firstDeposit) = $event)),
            multiple: false,
            placeholder: "Select",
            filterable: "",
            clearable: "",
            "fit-input-width": ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.firstDepositFilterOptions, (option) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: option.value,
                  label: option.text,
                  value: option.value
                }, null, 8 /* PROPS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "PSP info",
        prop: "state",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.filterFormData.state,
            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.filterFormData.state) = $event)),
            placeholder: "Input PSP info",
            clearable: ""
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "OS",
        prop: "os",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.filterFormData.os,
            "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.filterFormData.os) = $event)),
            loading: _ctx.isTransactionOperatingSystemListLoading,
            multiple: "",
            placeholder: "Select OS",
            filterable: "",
            clearable: "",
            "fit-input-width": ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transactionOperatingSystemList, (operationSystem) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: operationSystem.code,
                  label: operationSystem.code,
                  value: operationSystem.code
                }, null, 8 /* PROPS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "loading"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Email",
        prop: "email",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.filterFormData.email,
            "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.filterFormData.email) = $event)),
            placeholder: "Input email",
            clearable: ""
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Affiliate ID",
        prop: "affiliateId",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.filterFormData.affiliateId,
            "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.filterFormData.affiliateId) = $event)),
            placeholder: "Input affiliate id",
            clearable: ""
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Username",
        prop: "username",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.filterFormData.username,
            "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.filterFormData.username) = $event)),
            placeholder: "Input username",
            clearable: ""
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Account holder",
        prop: "accountHolderName",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.filterFormData.accountHolderName,
            "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.filterFormData.accountHolderName) = $event)),
            placeholder: "Input account holder",
            clearable: ""
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Transaction ID",
        prop: "transactionId",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.filterFormData.transactionId,
            "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.filterFormData.transactionId) = $event)),
            placeholder: "Input transaction ID",
            clearable: ""
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Admin email",
        class: "bonus-history-list-filter__item",
        prop: "adminEmail"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.filterFormData.backOfficeAdminEmail,
            "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.filterFormData.backOfficeAdminEmail) = $event)),
            filterable: "",
            remote: "",
            "reserve-keyword": "",
            clearable: "",
            placeholder: "Type and select admin email",
            "remote-show-suffix": "",
            placement: "top-start",
            "remote-method": _ctx.getAdminEmails,
            loading: _ctx.isAdminEmailListLoading,
            onClear: _ctx.clearAdminEmailList
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.adminEmailList, (admin) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: admin.adminId,
                  label: admin.adminEmail,
                  value: admin.adminEmail
                }, null, 8 /* PROPS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "remote-method", "loading", "onClear"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Payment account",
        prop: "paymentAccount",
        class: "transactions-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.filterFormData.paymentAccount,
            "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.filterFormData.paymentAccount) = $event)),
            placeholder: "Input payment account",
            clearable: ""
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 512 /* NEED_PATCH */))
}