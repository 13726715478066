import { ref, defineComponent, onMounted, PropType, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useAuth } from '@/store/auth.store';
import { useEnvStore } from '@/store/env.store';
import { storeToRefs } from 'pinia';
import { WhiteLabels } from '@/models/app-internal/whitelabels.model';
import { PARENT_ROUTE_NAMES } from '@/models/app-internal/env.model';
import moment from 'moment-timezone';
import useEmitter from '@/hooks/use-emitter';

export default defineComponent({
  name: 'Root',
  props: {
    wlSlug: {
      type: String as PropType<WhiteLabels>,
      required: true,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    multibrandApiUrl: {
      type: String,
      required: true,
    },
    servicePermissions: {
      type: String,
      default: '',
    },
    currentParentRouteName: {
      type: String as PropType<PARENT_ROUTE_NAMES>,
      default: '',
    },
  },
  setup (props) {
    const { token } = storeToRefs(useAuth());
    const root = ref();
    const { go } = useRouter();
    const { setEnv } = useEnvStore();
    const { setupRootEmitter } = useEmitter();

    const isBackofficeMode = computed(() => {
      return !!props.wlSlug && !!props.apiUrl;
    });

    onMounted(() => {
      if (!token.value && isBackofficeMode.value) {
        go(0);
      }

      setEnv(props);

      setupRootEmitter((name, value) =>
        root.value.parentNode.dispatchEvent(new CustomEvent(name, { detail: value })),
      );

      const timezone = localStorage.getItem('adminTimezone');

      if (timezone) {
        moment.tz.setDefault(timezone);
      }
    });

    watch(props, (updatedProps) => {
      setEnv(updatedProps);
    });

    return {
      root,
    };
  },
});
