import { RouteComponent, RouteRecordRaw } from 'vue-router';
import { psManagementRoutes } from '@/router/routes/ps-management/ps-management.routes';
import { transactionsRoutes } from '@/router/routes/transactions/transactions.routes';
import { presetSettingsRoutes } from '@/router/routes/preset-settings/preset-settings.routes';
import { logsRoutes } from '@/router/routes/logs-page/logs.routes';
import { pspSortingRoutes } from '@/router/routes/psp-sorting/psp-sorting.routes';

export const routes: RouteRecordRaw[] = [
  {
    name: 'home',
    path: '',
    component: async (): Promise<RouteComponent> => await import('@/layouts/home-page/home-page.vue'),
  },
  {
    name: 'permissions',
    path: '/permissions',
    component: async (): Promise<RouteComponent> => await import('@/layouts/permissions-page/permissions-page.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'home' },
  },
  ...psManagementRoutes,
  ...transactionsRoutes,
  ...presetSettingsRoutes,
  ...logsRoutes,
  ...pspSortingRoutes,
];
