import { computed, defineComponent, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useAvailableWhitelabelsConfigStore } from '@/store/multibrand/available-whitelabels-config-list.store';
import { usePspSortingStore } from '@/store/psp-sorting/psp-sorting.store';
import { usePspSortingListFilterStore } from '@/store/psp-sorting/psp-sorting-list-filter.store';
import { iconPageMultibrand } from '@/models/constants';
import { usePsManagementCountryListStore } from '@/store/ps-management/payment-methods-country-list.store';
import { Picture } from '@element-plus/icons-vue';

const icons = {
  Picture,
};

export default defineComponent({
  name: 'PspSortingPage',
  setup() {
    const pspSortingStore = usePspSortingStore();
    const { getPspSortingList, resetPspSortingList } = pspSortingStore;
    const { extendedPspSortingList, isPspSortingListLoading, brandFilters } = storeToRefs(pspSortingStore);

    const pspSortingListFilterStore = usePspSortingListFilterStore();
    const { pspSortingFilterFormData, isCountrySelected } = storeToRefs(pspSortingListFilterStore);

    const psManagementCountryListStore = usePsManagementCountryListStore();
    const { getPsManagementCountryList } = psManagementCountryListStore;
    const {
      psManagementCountryList,
      isPsManagementCountryListLoading,
    } = storeToRefs(psManagementCountryListStore);

    const { isOnlyOneWhitelabelAvailable } = storeToRefs(useAvailableWhitelabelsConfigStore());

    const pspSortingTableMergeCells = ({
      rowIndex,
      columnIndex,
    }: { rowIndex: number; columnIndex: number }): number[] | void => {
      if (columnIndex !== 0 && columnIndex !== 1) return;

      const row = extendedPspSortingList.value[rowIndex];

      return row.isFirst ? [row.rowspan, 1] : [0, 0];
    };

    const isBrandFilterVisible = computed<boolean>(
      () => isCountrySelected.value && !!extendedPspSortingList.value?.length,
    );

    const isCountrySelectLoading = computed<boolean>(
      () => isPsManagementCountryListLoading.value || isPspSortingListLoading.value,
    );

    onMounted(() => {
      getPsManagementCountryList();
    });

    return {
      icons,
      getPspSortingList,
      resetPspSortingList,
      extendedPspSortingList,
      isPspSortingListLoading,
      pspSortingTableMergeCells,
      iconPageMultibrand,
      isOnlyOneWhitelabelAvailable,
      brandFilters,
      isBrandFilterVisible,
      pspSortingFilterFormData,
      psManagementCountryList,
      isCountrySelected,
      isCountrySelectLoading,
    };
  },
});
