import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_select_v2 = _resolveComponent("el-select-v2")!

  return (_openBlock(), _createBlock(_component_el_select_v2, {
    modelValue: _ctx.valueList,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueList) = $event)),
    filterable: "",
    clearable: "",
    remote: "",
    multiple: _ctx.isMultiple,
    "collapse-tags": _ctx.isCollapseTags,
    "collapse-tags-tooltip": _ctx.isCollapseTagsTooltip,
    "remote-method": _ctx.autocompleteSearch,
    loading: _ctx.isLoading,
    options: _ctx.options,
    "label-key": _ctx.labelKey,
    "popper-class": "autocomplete-multiselect__popper",
    "value-key": _ctx.valueKey,
    placeholder: _ctx.placeholder,
    "no-data-text": "Nothing found",
    onFocus: _ctx.clearSearchResult,
    onChange: _ctx.updateSelectedValueList
  }, {
    label: _withCtx(({ label }) => [
      _createVNode(_component_el_tooltip, {
        effect: "light",
        content: label[_ctx.labelKey] || label
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(label[_ctx.labelKey] || label), 1 /* TEXT */)
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["content"])
    ]),
    default: _withCtx(({ item }) => [
      _createVNode(_component_el_tag, {
        size: "small",
        type: "info"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(item.label), 1 /* TEXT */)
        ]),
        _: 2 /* DYNAMIC */
      }, 1024 /* DYNAMIC_SLOTS */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "multiple", "collapse-tags", "collapse-tags-tooltip", "remote-method", "loading", "options", "label-key", "value-key", "placeholder", "onFocus", "onChange"]))
}