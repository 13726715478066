import { PAYMENTS_MODES, DEFAULT_PROPS, EnvConfig, PARENT_ROUTE_NAMES, Props } from '@/models/app-internal/env.model';
import { PAYMENTS_SECTIONS_ROUTES_META_TITLES } from '@/models/app-internal/meta-titles.model';
import { WhiteLabels } from '@/models/app-internal/whitelabels.model';
import router from '@/router/router';
import { defineStore } from 'pinia';
import { usePermissionsStore } from './permissions.store';

export const useEnvStore = defineStore({
  id: 'env',
  state: (): EnvConfig => ({
    APP_MODE: '',
    MOCKS_ENABLED: '0',
    BACKOFFICE_API_URL: DEFAULT_PROPS().apiUrl,
    MULTIBRAND_API_URL: DEFAULT_PROPS().multibrandApiUrl,
    WL_SLUG: DEFAULT_PROPS().wlSlug,
    CURRENT_PARTENT_ROUTE_NAME: DEFAULT_PROPS().currentParentRouteName,
  }),
  actions: {
    setEnv(props: Props): void {
      this.APP_MODE = !!props.wlSlug && !!props.apiUrl
        ? PAYMENTS_MODES.BACKOFFICE
        : PAYMENTS_MODES.STANDALONE;
      this.setWlSlug(props.wlSlug || DEFAULT_PROPS().wlSlug);
      this.setWlStyles();
      this.BACKOFFICE_API_URL = props.apiUrl || `${DEFAULT_PROPS().apiUrl}/${this.WL_SLUG}`;
      this.MULTIBRAND_API_URL = props.multibrandApiUrl;
      this.setServicePermissions(props.servicePermissions);
      this.setCurrentParentRouteName(props.currentParentRouteName);
    },
    setWlSlug(wlSlug: WhiteLabels): void {
      this.WL_SLUG = wlSlug;
      this.updateBackofficeApiUrl();
      this.setWlStyles();
    },
    updateBackofficeApiUrl(): void {
      this.BACKOFFICE_API_URL = `${DEFAULT_PROPS().apiUrl}/${this.WL_SLUG}`;
    },
    setServicePermissions(input: string): void {
      const { setPermissions } = usePermissionsStore();
      setPermissions(JSON.parse(input));
    },
    setWlStyles(): void {
      import (`@marfa/themes/css/${
        (this.WL_SLUG) as string
      }.css`);
    },
    setCurrentParentRouteName(currentParentRouteName: PARENT_ROUTE_NAMES): void {
      // Этот метод устанавливает текущий роут в микрофронте
      // при его изменении в родительском микрофронте
      this.CURRENT_PARTENT_ROUTE_NAME = currentParentRouteName;
      router.push({ name: currentParentRouteName }).finally(() => {
        this.setMetaTitle(PAYMENTS_SECTIONS_ROUTES_META_TITLES[currentParentRouteName] ?? 'Backoffice');
      });
    },
    setMetaTitle(title: string): void {
      const titleEl = document.querySelector('head title');

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      titleEl!.textContent = (title);
    },
  },
});
