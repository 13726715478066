import { defineStore, storeToRefs } from 'pinia';
import { api } from '@/services/http.service';
import { ElNotification } from 'element-plus';
import { debounce } from 'lodash';
import {
  TagListRequestParams,
  TagListItemResponseModel,
  TagListItem,
  AutocompleteTagListSearchResult,
} from '@/models/dictionary/tag-list.model';
import { useGeoPsManagementStore } from '@/store/ps-management/geo-payment-methods.store';

interface TagListStore {
  tagList: TagListItemResponseModel[];
  isTagListLoading: boolean;
  autocompleteTagListSearchResult: AutocompleteTagListSearchResult[];
}

export const useTagListStore = defineStore({
  id: 'TagListStore',
  state: (): TagListStore => ({
    tagList: [],
    isTagListLoading: false,
    autocompleteTagListSearchResult: [],
  }),
  getters: {
    geoPsMethodTagListWithoutIncluded(state: TagListStore): AutocompleteTagListSearchResult[] {
      const { geoPaymentMethodsSettingsFormData } = storeToRefs(useGeoPsManagementStore());

      return state.autocompleteTagListSearchResult.filter((tag) =>
        !geoPaymentMethodsSettingsFormData.value.tagList?.some(
          (selectedTag: TagListItem) => selectedTag.tagId === tag.value.tagId,
        ),
      );
    },
    geoPsMethodTagListWithoutExcluded(state: TagListStore): AutocompleteTagListSearchResult[] {
      const { geoPaymentMethodsSettingsFormData } = storeToRefs(useGeoPsManagementStore());

      return state.autocompleteTagListSearchResult.filter((tag) =>
        !geoPaymentMethodsSettingsFormData.value.excludedTagList?.some(
          (selectedTag: TagListItem) => selectedTag.tagId === tag.value.tagId,
        ),
      );
    },
  },
  actions: {
    setTagListLoading(isLoading: boolean): void {
      this.isTagListLoading = isLoading;
    },
    getTagList(requestParams: TagListRequestParams): Promise<TagListItemResponseModel[] | void> {
      this.setTagListLoading(true);

      return api.dictionary.getTagList.perform({
        object: requestParams.object,
        filterList: requestParams.filterList,
      })
        .then((response: TagListItemResponseModel[]) => {
          this.tagList = response;

          return response;
        })
        .catch(error => {
          ElNotification({
            title: 'Error',
            message: error?.response?.data?.message,
            type: 'error',
          });
        })
        .finally(() => {
          this.setTagListLoading(false);
        });
    },
    clearTagList(): void {
      this.tagList = [];
    },
    clearAutocompleteTagListSearchResult(): void {
      this.autocompleteTagListSearchResult = [];
    },
    tagListAutocompleteSearch(
      { name, alreadySelectedTagList }:
      { name: string; alreadySelectedTagList?: TagListItemResponseModel[] }): void | [] {
      const performSearch = debounce(() => {
        if (name?.length < 2 && name?.length !== 0) {
          this.autocompleteTagListSearchResult = [{
            label: 'Minimum 2 characters',
            value: {
              tagId: '',
              name: '',
              fullCoverage: false,
              editable: false,
              wlList: [],
              color: '',
              sortingWeight: null,
            },
            disabled: true,
          }];

          return;
        }

        if (!alreadySelectedTagList?.length && !name) {
          this.clearAutocompleteTagListSearchResult();

          return;
        }

        const filterList: TagListRequestParams = {
          object: 'player',
          filterList: { name },
        };

        this.getTagList(filterList).then((tagList) => {
          if (!tagList) {
            return this.autocompleteTagListSearchResult;
          }

          const autocompleteSearchResult: AutocompleteTagListSearchResult[] = tagList.map(tag => ({
            label: tag.name,
            value: {
              ...tag,
              id: tag.tagId,
            },
          }));

          const alreadySelectedTagFallbackList: AutocompleteTagListSearchResult[] =
            autocompleteSearchResult.filter((tag: AutocompleteTagListSearchResult) =>
              alreadySelectedTagList?.some((selectedTag: TagListItem) =>
                tag.value.tagId === selectedTag.tagId,
              ),
            );

          this.autocompleteTagListSearchResult = !name
            ? alreadySelectedTagFallbackList
            : autocompleteSearchResult;
        });
      }, 300);

      performSearch();
    },
  },
});
