import { api } from '@/services/http.service';
import { defineStore } from 'pinia';
import { CountryItem } from '@/models/dictionary/country-list.model';
import { ElNotification } from 'element-plus';
import { useCountryListStore } from '@/store/dictionary/country-list.store';

interface PsManagementCountryListStore {
  psManagementCountryList: CountryItem[];
  isPsManagementCountryListLoading: boolean;
  selectedCountry: CountryItem | null;
  isAddCountriesDialogVisible: boolean;
  paymentMethodsGeoSelectRef: {} | null;
}

export const usePsManagementCountryListStore = defineStore({
  id: 'PSManagementCountryListStore',
  state: (): PsManagementCountryListStore => ({
    psManagementCountryList: [],
    isPsManagementCountryListLoading: false,
    selectedCountry: null,
    isAddCountriesDialogVisible: false,
    paymentMethodsGeoSelectRef: null,
  }),
  getters: {
    isGlobalSettingsMode(): boolean {
      return !this.selectedCountry;
    },
    availableNewCountryList(store): CountryItem[] {
      const { countryList } = useCountryListStore();

      return countryList.filter(
        country => !store.psManagementCountryList.find(item => item.code === country.code),
      );
    },
  },
  actions: {
    setAddCountriesDialogVisibility(isVisible: boolean) {
      if (isVisible) {
        if (!this.paymentMethodsGeoSelectRef) {
          return;
        }

        const { getCountryList } = useCountryListStore();
        getCountryList();
        // @ts-ignore
        this.paymentMethodsGeoSelectRef.blur();
      }

      this.isAddCountriesDialogVisible = isVisible;
    },
    closeAddCountriesDialog() {
      this.setAddCountriesDialogVisibility(false);
    },
    openAddCountriesDialog() {
      this.setAddCountriesDialogVisibility(true);
    },
    setPsManagementCountryListLoading(isLoading: boolean) {
      if (this.isPsManagementCountryListLoading === isLoading) {
        return;
      }

      this.isPsManagementCountryListLoading = isLoading;
    },
    getPsManagementCountryList(options?: { forceRefresh?: boolean }): Promise<CountryItem[] | void> {
      if (this.psManagementCountryList.length && !options?.forceRefresh) {
        return Promise.resolve(this.psManagementCountryList);
      }

      this.setPsManagementCountryListLoading(true);

      return api.psManagement.getPaymentMethodCountryListRequest.perform()
        .then(response => {
          this.psManagementCountryList = response;
        }).catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });
        }).finally(() => this.setPsManagementCountryListLoading(false));
    },
    addPsManagementCountries(countryList: CountryItem[]): Promise<void> {
      if (!countryList.length) {
        return Promise.resolve();
      }

      this.setPsManagementCountryListLoading(true);

      return api.psManagement.addPaymentMethodCountriesRequest.perform(countryList)
        .then(() => {
          ElNotification({
            title: 'Success',
            message: 'Countries successfully added',
            type: 'success',
          });

          this.closeAddCountriesDialog();
          this.getPsManagementCountryList({ forceRefresh: true });
        }).catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });
        }).finally(() => this.setPsManagementCountryListLoading(false));
    },
  },
});
