import { WhiteLabels } from './whitelabels.model';

export enum ENV_VAR_NAMES {
  APP_MODE = 'APP_MODE',
  MOCKS_ENABLED = 'MOCKS_ENABLED',
  BACKOFFICE_API_URL = 'BACKOFFICE_API_URL',
  WL_SLUG = 'WL_SLUG',
  BONUS_PERMISSIONS = 'BONUS_PERMISSIONS',
  CURRENT_PARTENT_ROUTE_NAME = 'CURRENT_PARTENT_ROUTE_NAME',
  MULTIBRAND_API_URL = 'MULTIBRAND_API_URL',
}

export enum PAYMENTS_MODES {
  STANDALONE = 'standalone',
  BACKOFFICE = 'backoffice',
}

export type EnvConfig = {
  [ENV_VAR_NAMES.APP_MODE]: PAYMENTS_MODES | string;
  [ENV_VAR_NAMES.MOCKS_ENABLED]: string;
  [ENV_VAR_NAMES.BACKOFFICE_API_URL]: string;
  [ENV_VAR_NAMES.WL_SLUG]: WhiteLabels;
  [ENV_VAR_NAMES.CURRENT_PARTENT_ROUTE_NAME]: PARENT_ROUTE_NAMES;
  [ENV_VAR_NAMES.MULTIBRAND_API_URL]: string;
};

export type EnvVariables = Record<string, string | undefined>;

export const DEFAULT_PROPS = (): DefaultProps => {
  return {
    apiUrl: 'https://dev.aramuz.net/backofficeapi',
    multibrandApiUrl: '',
    wlSlug: localStorage.getItem('defaultWlSlug') as WhiteLabels || WhiteLabels.NINECASINO,
    currentParentRouteName: '',
    servicePermissions: '',
  };
};

export type Props = {
  apiUrl: string;
  multibrandApiUrl: string;
  wlSlug: WhiteLabels;
  currentParentRouteName: PARENT_ROUTE_NAMES;
  servicePermissions: string;
}

export type DefaultProps = {
  apiUrl: Props['apiUrl'];
  multibrandApiUrl: Props['multibrandApiUrl'];
  wlSlug: Props['wlSlug'];
  currentParentRouteName: Props['currentParentRouteName'];
  servicePermissions: Props['servicePermissions'];
}

export type PARENT_ROUTE_NAMES = '' | 'home';
/* Указываем все дочерние роуты этого микрофронта
для каждого из них создаем enum — один подраздел, один enum
export type PARENT_ROUTE_NAMES = '' | OTHER_ROUTE_NAMES | SOME_OTHER_ROUTE_NAMES;
*/
