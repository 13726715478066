import { BillingProdiverListItem, BillingProviderListFilter } from '@/models/dictionary/billing-provider-list.model';
import { api } from '@/services/http.service';
import { ElNotification } from 'element-plus';
import { defineStore } from 'pinia';

interface BillingProviderListState {
  billingProviderList: BillingProdiverListItem[];
  isBillingProviderListLoading: boolean;
}

export const useBillingProviderListStore = defineStore({
  id: 'BillingProviderListStore',
  state: (): BillingProviderListState => ({
    billingProviderList: [],
    isBillingProviderListLoading: false,
  }),
  actions: {
    setBillingProviderListLoading(isLoading: boolean): void {
      this.isBillingProviderListLoading = isLoading;
    },
    getBillingProviderList(filterList?: BillingProviderListFilter): Promise<BillingProdiverListItem[] | void> {
      this.setBillingProviderListLoading(true);

      return api.dictionary.getBillingProviderListRequest.perform(filterList)
        .then((data) => {
          this.billingProviderList = data;
        })
        .catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });

          return Promise.reject(error);
        })
        .finally(() => {
          this.setBillingProviderListLoading(false);
        });
    },
  },
});
