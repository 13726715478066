import { HttpMethods, RequestPerformer } from '@/api/api-requests-factory';
import { Ref, ref } from 'vue';
import GetGeoPaymentMethodListRequest
  from '@/api/ps-management/geo-payment-methods/get-geo-payment-method-list.request';
import UpdateFullGeoPaymentMethodListRequest
  from '@/api/ps-management/geo-payment-methods/update-sort-geo-payment-method-list.request';
import PartialUpdateGeoPaymentMethodRequest
  from '@/api/ps-management/geo-payment-methods/partial-update-geo-payment-method.request';
import GetGlobalPaymentMethodListRequest
  from '@/api/ps-management/global-payment-methods/get-global-payment-method-list.request';
import CreateGlobalPaymentMethodRequest
  from '@/api/ps-management/global-payment-methods/create-global-payment-method.request';
import DeleteGlobalPaymentMethodRequest
  from '@/api/ps-management/global-payment-methods/delete-global-payment-method.request';
import GetGlobalPaymentMethodRequest
  from '@/api/ps-management/global-payment-methods/get-global-payment-method.request';
import UpdateGlobalPaymentMethodRequest
  from '@/api/ps-management/global-payment-methods/update-global-payment-method.request';
import PartialUpdateGlobalPaymentMethodRequest
  from '@/api/ps-management/global-payment-methods/partial-update-global-payment-method.request';
import GetPaymentMethodCountryListRequest
  from '@/api/ps-management/country-list/get-payment-method-country-list.request';
import AddPaymentMethodCountriesRequest from '@/api/ps-management/country-list/add-payment-methos-countries.request';
import UpdateGeoPaymentMethodRequest from '@/api/ps-management/geo-payment-methods/update-geo-payment-method.request';
import GetGeoPaymentMethodDepositLimitsRequest
  from '@/api/ps-management/payment-method-limits/get-geo-deposit-limits.request';
import DeleteGeoPaymentMethodRequest from '@/api/ps-management/geo-payment-methods/delete-geo-payment-method.request';
import GetGeoPaymentMethodRequest from '@/api/ps-management/geo-payment-methods/get-geo-payment-method.request';
import UpdateGeoPaymentMethodDepositLimitsRequest
  from '@/api/ps-management/payment-method-limits/update-geo-deposit-limits.request';
import GetGeoPaymentMethodDepositLimitsListRequest
  from '@/api/ps-management/payment-method-limits/get-geo-deposit-limits-list.request';
import GetBillingProviderListRequest from '@/api/ps-management/get-billing-provider-list.request';
import CreatePaymentMethodCredentialsRequest
  from '@/api/ps-management/global-payment-methods/create-payment-method-credentials.request';

export class PsManagementApiResources {
  private readonly billingProviderApiUrl: Ref<string>;

  public constructor(
    private readonly requestPerformer: RequestPerformer,
    private readonly apiUrl: Ref<string>,
  ) {
    this.billingProviderApiUrl = ref(`${this.apiUrl.value}/billingProvider`);
  }

  public get getBillingProviderListRequest(): GetBillingProviderListRequest {
    return new GetBillingProviderListRequest(
      this.requestPerformer,
      `${this.billingProviderApiUrl.value}`,
      HttpMethods.GET,
    );
  }

  // ====================================
  // === GEO PAYMENT METHODS REQUESTS ===
  // ====================================

  public get createPaymentMethodCredentialsRequest(): CreatePaymentMethodCredentialsRequest {
    return new CreatePaymentMethodCredentialsRequest(
      this.requestPerformer,
      `${this.billingProviderApiUrl.value}/paymentMethodCredentials`,
      HttpMethods.POST,
    );
  }

  public get getGeoPaymentMethodListRequest(): GetGeoPaymentMethodListRequest {
    return new GetGeoPaymentMethodListRequest(
      this.requestPerformer,
      `${this.billingProviderApiUrl.value}/paymentMethodSettings`,
      HttpMethods.GET,
    );
  }

  public get updateFullGeoPaymentMethodListRequest(): UpdateFullGeoPaymentMethodListRequest {
    return new UpdateFullGeoPaymentMethodListRequest(
      this.requestPerformer,
      `${this.billingProviderApiUrl.value}/paymentMethodSettings`,
      HttpMethods.PATCH,
    );
  }

  public get updateGeoPaymentMethodRequest(): UpdateGeoPaymentMethodRequest {
    return new UpdateGeoPaymentMethodRequest(
      this.requestPerformer,
      `${this.billingProviderApiUrl.value}/paymentMethodSettings`,
      HttpMethods.PATCH,
    );
  }

  public get partialUpdateGeoPaymentMethodRequest(): PartialUpdateGeoPaymentMethodRequest {
    return new PartialUpdateGeoPaymentMethodRequest(
      this.requestPerformer,
      `${this.billingProviderApiUrl.value}/paymentMethodSettings`,
      HttpMethods.PATCH,
    );
  }

  public get deleteGeoPaymentMethodRequest(): DeleteGeoPaymentMethodRequest {
    return new DeleteGeoPaymentMethodRequest(
      this.requestPerformer,
      `${this.billingProviderApiUrl.value}/paymentMethodSettings`,
      HttpMethods.DELETE,
    );
  }

  public get getGeoPaymentMethodRequest(): GetGeoPaymentMethodRequest {
    return new GetGeoPaymentMethodRequest(
      this.requestPerformer,
      `${this.billingProviderApiUrl.value}/paymentMethodSettings`,
      HttpMethods.GET,
    );
  }

  // =======================================
  // === GLOBAL PAYMENT METHODS REQUESTS ===
  // =======================================

  public get getGlobalPaymentMethodListRequest(): GetGlobalPaymentMethodListRequest {
    return new GetGlobalPaymentMethodListRequest(
      this.requestPerformer,
      `${this.billingProviderApiUrl.value}/paymentMethod`,
      HttpMethods.GET,
    );
  }

  public get createGlobalPaymentMethodRequest(): CreateGlobalPaymentMethodRequest {
    return new CreateGlobalPaymentMethodRequest(
      this.requestPerformer,
      `${this.billingProviderApiUrl.value}/paymentMethod`,
      HttpMethods.POST,
    );
  }

  public get deleteGlobalPaymentMethodRequest(): DeleteGlobalPaymentMethodRequest {
    return new DeleteGlobalPaymentMethodRequest(
      this.requestPerformer,
      `${this.billingProviderApiUrl.value}/paymentMethod`,
      HttpMethods.DELETE,
    );
  }

  public get getGlobalPaymentMethodRequest(): GetGlobalPaymentMethodRequest {
    return new GetGlobalPaymentMethodRequest(
      this.requestPerformer,
      `${this.billingProviderApiUrl.value}/paymentMethod`,
      HttpMethods.GET,
    );
  }

  public get updateGlobalPaymentMethodRequest(): UpdateGlobalPaymentMethodRequest {
    return new UpdateGlobalPaymentMethodRequest(
      this.requestPerformer,
      `${this.billingProviderApiUrl.value}/paymentMethod`,
      HttpMethods.PUT,
    );
  }

  public get partialUpdateGlobalPaymentMethodRequest(): PartialUpdateGlobalPaymentMethodRequest {
    return new PartialUpdateGlobalPaymentMethodRequest(
      this.requestPerformer,
      `${this.billingProviderApiUrl.value}/paymentMethod`,
      HttpMethods.PATCH,
    );
  }

  // ======================================
  // === PAYMENT SYSTEMS COUNTRIES LIST ===
  // ======================================

  public get getPaymentMethodCountryListRequest(): GetPaymentMethodCountryListRequest {
    return new GetPaymentMethodCountryListRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/paymentMethodGeo`,
      HttpMethods.GET,
    );
  }

  public get addPaymentMethodCountriesRequest(): AddPaymentMethodCountriesRequest {
    return new AddPaymentMethodCountriesRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/paymentMethodGeo`,
      HttpMethods.POST,
    );
  }

  // ==============================
  // === PAYMENT SYSTEMS LIMITS ===
  // ==============================
  public get getGeoPaymentMethodDepositLimitsRequest(): GetGeoPaymentMethodDepositLimitsRequest {
    return new GetGeoPaymentMethodDepositLimitsRequest(
      this.requestPerformer,
      `${this.billingProviderApiUrl.value}/billingPaymentMethodDepositLimit`,
      HttpMethods.GET,
    );
  }

  public get updateGeoPaymentMethodDepositLimitsRequest(): UpdateGeoPaymentMethodDepositLimitsRequest {
    return new UpdateGeoPaymentMethodDepositLimitsRequest(
      this.requestPerformer,
      `${this.billingProviderApiUrl.value}/billingPaymentMethodDepositLimit`,
      HttpMethods.PATCH,
    );
  }

  public get getGeoPaymentMethodDepositLimitsListRequest(): GetGeoPaymentMethodDepositLimitsListRequest {
    return new GetGeoPaymentMethodDepositLimitsListRequest(
      this.requestPerformer,
      `${this.billingProviderApiUrl.value}/billingPaymentMethodDepositLimit`,
      HttpMethods.GET,
    );
  }
}
