import { DEFAULT_ITEMS_PER_PAGE_LIST } from '@/models/constants';
import { TransactionListItem } from '@/models/transaction/transaction-list.model';
import { defineStore, storeToRefs } from 'pinia';
import { useTransactionListFilterStore } from './transaction-list-filter.store';
import { clearObjIncludesFields } from '@/utils/form.utils';
import { formatDatesInPayload } from '@/utils/date-time.format.util';
import { api } from '@/services/http.service';
import { ElNotification } from 'element-plus';
import useTokenPagination from '@/hooks/use-token-pagination.hook';

type TransactionListStore = {
  isTransactionListLoading: boolean;
  transactionList: TransactionListItem[];
  limit: number;
  sort: string;
}

export const useTransactionListStore = defineStore({
  id: 'TransactionListStore',
  state: (): TransactionListStore => ({
    isTransactionListLoading: false,
    transactionList: [],
    limit: DEFAULT_ITEMS_PER_PAGE_LIST[0],
    sort: '',
  }),
  getters: {
    transactionListWithFormattedDates(state: TransactionListStore): TransactionListItem[] {
      return state.transactionList.map((transactionItem: TransactionListItem) => formatDatesInPayload({
        payload: transactionItem,
        unixToString: true,
        includeTime: true,
      }));
    },
  },
  actions: {
    setTransactionListLoading(isLoading: boolean): void {
      this.isTransactionListLoading = isLoading;
    },
    setLimit(limit: number): void {
      this.limit = limit;
    },
    setSort(sortOrder: string): void {
      this.sort = sortOrder;
    },
    getTransactionList(): Promise<TransactionListItem[] | void> {
      this.setTransactionListLoading(true);

      const { submittedFilterFormData } = storeToRefs(useTransactionListFilterStore());
      const {
        prevPageToken,
        nextPageToken,
        processResponseHeaders,
      } = useTokenPagination();

      const requestPayload = {
        limit: this.limit,
        prevPageToken: prevPageToken.value,
        nextPageToken: nextPageToken.value,
        filterList: clearObjIncludesFields(submittedFilterFormData.value),
        sort: this.sort,
      };

      return api.transactions.getTransactionListRequest.perform(requestPayload)
        .then(({ data, headers }) => {
          this.transactionList = data;

          processResponseHeaders(headers);
        })
        .catch((error) => {
          ElNotification({
            title: 'Error',
            message: error,
            type: 'error',
          });
        })
        .finally(() => {
          this.setTransactionListLoading(false);
        });
    },
  },
});
