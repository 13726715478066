import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { PspSortingListFilter } from '@/models/psp-sorting/psp-sorting-filter.model';
import type { PspSortingItem } from '@/models/psp-sorting/psp-sorting.model';

export default class GetPspSortingListRequest extends ApiResourceMethod<PspSortingItem[]> {
  public perform(filterList: PspSortingListFilter): Promise<PspSortingItem[]> {
    const params = {
      filterList,
    };

    return this.performer.requestWithMapper(
      { params },
      (response: BaseResponse<PspSortingItem[]>) => response.data,
    );
  }
}
