import { PropType, defineComponent, onMounted, ref } from 'vue';
import {
  DArrowLeft,
  ArrowLeft,
  ArrowRight,
} from '@element-plus/icons-vue';
import { DEFAULT_ITEMS_PER_PAGE_LIST } from '@/models/constants';
import useTokenPagination, { TokenPaginationEvents } from '@/hooks/use-token-pagination.hook';

const icons = {
  DArrowLeft,
  ArrowLeft,
  ArrowRight,
};

export default defineComponent({
  name: 'TokenPaginationComponent',
  props: {
    itemsPerPageList: {
      type: Array as PropType<string[]>,
      default: DEFAULT_ITEMS_PER_PAGE_LIST,
    },
  },
  emits: [
    TokenPaginationEvents.UPDATE_LIMIT,
    TokenPaginationEvents.CHANGE_PAGE,
  ],
  setup(_props, { emit }) {
    const itemsPerPage = ref(DEFAULT_ITEMS_PER_PAGE_LIST[0]);

    const { nextPageToken, prevPageToken, updatePagination, resetPaginationTokens } = useTokenPagination();

    onMounted(() => {
      resetPaginationTokens();
    });

    const updateItemsPerPage = (limit: number): void => {
      emit(TokenPaginationEvents.UPDATE_LIMIT, limit);
      updatePagination({});
      emit(TokenPaginationEvents.CHANGE_PAGE);
    };

    const changePageHandler = (changePageEvent: string): void => {
      const changePageEventsMapping = {
        next: () => {
          updatePagination({ nextPageToken: nextPageToken.value });
        },
        prev: () => {
          updatePagination({ prevPageToken: prevPageToken.value });
        },
        default: () => {
          updatePagination({});
        },
      };

      changePageEventsMapping[changePageEvent]();
      emit(TokenPaginationEvents.CHANGE_PAGE);
    };

    return {
      icons,
      itemsPerPage,
      updateItemsPerPage,
      changePageHandler,
      nextPageToken,
      prevPageToken,
    };
  },
});
