import { defineStore } from 'pinia';
import { DEFAULT_PSP_SORTING_FILTER, PspSortingListFilter } from '@/models/psp-sorting/psp-sorting-filter.model';
import { usePspSortingStore } from '@/store/psp-sorting/psp-sorting.store';

type PspSortingListFilterStore = {
  pspSortingFilterFormData: PspSortingListFilter;
}

export const usePspSortingListFilterStore = defineStore({
  id: 'PspSortingListFilterStore',
  state: (): PspSortingListFilterStore => ({
    pspSortingFilterFormData: { ...DEFAULT_PSP_SORTING_FILTER },
  }),
  getters: {
    isCountrySelected(state: PspSortingListFilterStore): boolean {
      return !!state.pspSortingFilterFormData.country;
    },
  },
  actions: {
    resetPspSortingFilterBrand(): void {
      this.pspSortingFilterFormData.brand = null;
    },
    setPspSortingFilterFormData(filterData: PspSortingListFilter): void {
      this.pspSortingFilterFormData = { ...filterData };
    },
    clearPspSortingFilterFormData(): void {
      this.pspSortingFilterFormData = { ...DEFAULT_PSP_SORTING_FILTER };
    },
    resetPspSortingFilter(): void {
      const { getPspSortingList } = usePspSortingStore();

      this.clearPspSortingFilterFormData();
      getPspSortingList();
    },
  },
});
