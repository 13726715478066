import { computed, defineComponent, PropType, reactive, ref } from 'vue';
import { GlobalPaymentMethodItem, PaymentMethodCredentialsEntry } from '@/models/ps-management/ps-management.model';
import { CopyDocument, InfoFilled, Lock } from '@element-plus/icons-vue';
import { ElNotification, type FormInstance, FormRules } from 'element-plus';
import { api } from '@/services/http.service';
import { useGlobalPsManagementStore } from '@/store/ps-management/global-payment-methods.store';

const icons = {
  CopyDocument,
  InfoFilled,
  Lock,
};

export default defineComponent({
  name: 'AddSecretKeySidebar',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    paymentMethod: {
      type: Object as PropType<GlobalPaymentMethodItem>,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { getGlobalPaymentMethodList } = useGlobalPsManagementStore();

    const formRef = ref<FormInstance>();
    const isSubmitFormLoading = ref(false);
    const formData = reactive<PaymentMethodCredentialsEntry>({
      secret: '',
      twoFactorCode: '',
    });

    const isValidJSON = (value: string): boolean => {
      try {
        JSON.parse(value);

        return true;
      } catch {
        return false;
      }
    };

    const validateSecret = (rule: any, value: string, callback: (error?: Error) => void) => {
      if (!value.trim()) {
        callback(new Error('Basic form cannot be empty'));
      } else if (!isValidJSON(value)) {
        callback(new Error('Invalid JSON format'));
      } else {
        try {
          const parsedValue = JSON.parse(value);

          if (typeof parsedValue !== 'object' || parsedValue === null) {
            callback(new Error('JSON must represent an object'));
          } else {
            callback();
          }
        } catch (error) {
          callback(new Error('Invalid JSON format'));
        }
      }
    };

    const rules = reactive<FormRules<PaymentMethodCredentialsEntry>>({
      secret: [{ validator: validateSecret, trigger: 'blur' }],
      twoFactorCode: [
        { required: true, message: 'Please input 2FA code', trigger: 'blur' },
        { min: 6, max: 6, message: '2FA code must be 6 digits long', trigger: 'blur' },
      ],
    });

    const title = computed(() => {
      const { secretsAdded } = props.paymentMethod;

      return (typeof secretsAdded === 'boolean' && secretsAdded)
        ? 'Add new secret key'
        : 'Add secret key';
    });

    const visible = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });

    const secretsAdded = computed(() => {
      return props.paymentMethod.secretsAdded === true;
    });

    const secretsFormat = computed(() => {
      if (!props.paymentMethod) {
        return;
      }

      if (typeof props.paymentMethod.secretsFormat !== 'string') {
        return;
      }

      return JSON.parse(props.paymentMethod.secretsFormat);
    });

    const cleanExampleObjectForEditing = (obj: Record<string, any>, keysToKeep: string[]) => {
      const cleanedObject: Record<string, any> = {};

      for (const key in obj) {
        if (keysToKeep.includes(key)) {
          cleanedObject[key] = obj[key];
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          cleanedObject[key] = cleanExampleObjectForEditing(obj[key], []);
        } else {
          cleanedObject[key] = '';
        }
      }

      return cleanedObject;
    };

    const createCleanedSecretExample = () => {
      formData.secret = JSON.stringify(
        cleanExampleObjectForEditing(secretsFormat.value, ['bpId', 'psp', 'paymentSystem']),
        null,
        2,
      );
    };

    const beforeClose = () => {
      visible.value = false;
    };

    const submitForm = async (formRef?: FormInstance) => {
      if (!formRef) {
        return;
      }

      await formRef.validate((valid) => {
        if (!valid) {
          return;
        }

        isSubmitFormLoading.value = true;

        const payload: PaymentMethodCredentialsEntry = {
          secret: formData.secret.replace(/(\r\n|\n|\r)/gm, ''),
          twoFactorCode: formData.twoFactorCode,
        };

        api
          .psManagement
          .createPaymentMethodCredentialsRequest
          .perform(props.paymentMethod?.billingPaymentMethodId, payload)
          .then(() => {
            ElNotification.success({ message: 'Successfully' });

            getGlobalPaymentMethodList();
            beforeClose();
          }).finally(() => {
            isSubmitFormLoading.value = false;
          });
      });
    };

    return {
      icons,
      visible,
      title,
      beforeClose,
      secretsAdded,
      secretsFormat,
      isSubmitFormLoading,
      createCleanedSecretExample,
      formData,
      rules,
      submitForm,
      formRef,
    };
  },
});
