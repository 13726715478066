import { defineComponent, PropType, Ref, ref } from 'vue';
import { AutocompleteSelectEvents, SelectedValueListItem }
  from '@/models/autocomplete-select/autocomplete-select.model';
import { StatusTypes } from '@/models/app-common.model';

export default defineComponent({
  name: 'AutocompleteSelect',
  props: {
    isLoading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    isMultiple: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isCollapseTags: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isCollapseTagsTooltip: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    selectedValueList: {
      type: Array as PropType<SelectedValueListItem[]>,
      default: () => [],
    },
    valueKey: {
      type: String as PropType<string>,
      default: 'value',
    },
    labelKey: {
      type: String as PropType<string>,
      default: 'label',
    },
    placeholder: {
      type: String as PropType<string>,
      default: 'Select',
    },
    remoteMethod: {
      type: Function as PropType<(query: string) => void>,
      default: () => ({}),
    },
  },
  emits: [
    AutocompleteSelectEvents.REMOTE_METHOD,
    AutocompleteSelectEvents.CLEAR_SEARCH_RESULT,
    AutocompleteSelectEvents.AUTOCOMPLETE_SEARCH,
    AutocompleteSelectEvents.UPDATE_SELECTED_VALUE_LIST,
  ],
  setup(props, { emit }) {
    const autocompleteSearch = (query: string): void => {
      emit(AutocompleteSelectEvents.AUTOCOMPLETE_SEARCH, query);
      props.remoteMethod(query);
    };

    const clearSearchResult = (): void => {
      emit(AutocompleteSelectEvents.CLEAR_SEARCH_RESULT);
    };

    const valueList: Ref<SelectedValueListItem[]> = ref<SelectedValueListItem[]>(props.selectedValueList);

    const updateSelectedValueList = (selectedValue: SelectedValueListItem[]): void => {
      emit(AutocompleteSelectEvents.UPDATE_SELECTED_VALUE_LIST, selectedValue);
    };

    return {
      emit,
      autocompleteSearch,
      clearSearchResult,
      valueList,
      AutocompleteSelectEvents,
      updateSelectedValueList,
      StatusTypes,
    };
  },
});
