import { defineStore } from 'pinia';
import { api } from '@/services/http.service';
import { ElNotification } from 'element-plus';
import { AvailableWhitelabelConfigItem } from '@/models/multibrand/multibrand.model';

interface AvailableWhitelabelsConfigStore {
  availableWhitelabelConfigList: AvailableWhitelabelConfigItem[];
  isAvailableWhitelabelConfigListLoading: boolean;
  selectedWhitelabelSlug: string;
}

export const useAvailableWhitelabelsConfigStore = defineStore({
  id: 'AvailableWhitelabelsConfigStore',
  state: (): AvailableWhitelabelsConfigStore => ({
    availableWhitelabelConfigList: [],
    isAvailableWhitelabelConfigListLoading: false,
    selectedWhitelabelSlug: sessionStorage.getItem('selectedWhitelabelSlug') || '',
  }),
  getters: {
    isOnlyOneWhitelabelAvailable: (state: AvailableWhitelabelsConfigStore): boolean => {
      return state.availableWhitelabelConfigList.length === 1;
    },
  },
  actions: {
    setAvailableWhitelabelConfigListLoading(isLoading: boolean): void {
      this.isAvailableWhitelabelConfigListLoading = isLoading;
    },
    getAvailableWhitelabelConfigList(): Promise<AvailableWhitelabelConfigItem[] | void> {
      if (this.availableWhitelabelConfigList.length) {
        return Promise.resolve(this.availableWhitelabelConfigList);
      }

      this.setAvailableWhitelabelConfigListLoading(true);

      return api.multibrand.getAvailableWhitelabelConfigListRequest
        .perform()
        .then((response: AvailableWhitelabelConfigItem[]) => {
          this.availableWhitelabelConfigList = response;

          return response;
        }).catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });
        }).finally(() => {
          this.setAvailableWhitelabelConfigListLoading(false);
        });
    },
  },
});
