import { storeToRefs } from 'pinia';
import { computed, defineComponent, onMounted } from 'vue';
// eslint-disable-next-line max-len
import GeoLimitCurrencyConfiguration from '@/layouts/ps-management-page/geo-payment-methods-settings/geo-limit-currency-configuration/geo-limit-currency-configuration.vue';
import { useCurrencyListStore } from '@/store/dictionary/currency-list.store';
import UploadImageComponent from '@/components/upload-image-component/upload-image-component.vue';
import SegmentsAutocompleteMultiselect
  from '@/components/segments-autocomplete-multiselect/segments-autocomplete-multiselect.vue';
import { FormInstance } from 'element-plus';
import { useUploadImageStore } from '@/store/upload-image.store';
import {
  geoPaymentMethodsSettingsFormRef,
  useGeoPsManagementStore,
} from '@/store/ps-management/geo-payment-methods.store';
import { geoPaymentMethodsFormRules } from '@/hooks/ps-management/geo-payment-methods-validation-rules';

export default defineComponent({
  name: 'GeoPaymentMethodsSettings',
  components: {
    GeoLimitCurrencyConfiguration,
    UploadImageComponent,
    SegmentsAutocompleteMultiselect,
  },
  emits: ['updatePaymentMethodList'],
  setup(_props, { emit }) {
    const geoPsManagementStore = useGeoPsManagementStore();
    const {
      updateGeoPaymentMethod,
      closeGeoPaymentMethodSettings,
    } = geoPsManagementStore;
    const {
      geoPaymentMethodsSettingsFormData,
      isGeoPaymentMethodSettingsSidebarVisible,
      isGeoPaymentMethodLoading,
      isWithdrawColumnsDataVisible,
      isDepositColumnsDataVisible,
    } = storeToRefs(geoPsManagementStore);

    const currencyStore = useCurrencyListStore();
    const { isCurrencyListLoading } = storeToRefs(currencyStore);
    const { getCurrencyList } = currencyStore;

    const uploadImageStore = useUploadImageStore();
    const { uploadFile } = uploadImageStore;
    const { selectedImage } = storeToRefs(uploadImageStore);

    const isGeoPaymentMethodsFormDataLoading = computed(() =>
      isCurrencyListLoading.value || isGeoPaymentMethodLoading.value,
    );

    onMounted(() => {
      getCurrencyList();
    });

    const saveGeoPaymentMethods = (geoForm: FormInstance | undefined): void => {
      if (!geoForm) return;

      geoForm.validate((valid) => {
        if (!valid) {
          return;
        }

        if (selectedImage.value) {
          uploadFile().then((uploadedFilePath) => {
            if (!uploadedFilePath) {
              return;
            }

            geoPaymentMethodsSettingsFormData.value.imageId = uploadedFilePath.uploadedImageFileId;

            updateGeoPaymentMethod(geoPaymentMethodsSettingsFormData.value)
              .then(() => emit('updatePaymentMethodList'));
          });
        } else {
          updateGeoPaymentMethod(geoPaymentMethodsSettingsFormData.value)
            .then(() => emit('updatePaymentMethodList'));
        }
      });
    };

    return {
      geoPaymentMethodsSettingsFormData,
      geoPaymentMethodsSettingsFormRef,
      isGeoPaymentMethodsFormDataLoading,
      saveGeoPaymentMethods,
      closeGeoPaymentMethodSettings,
      isGeoPaymentMethodSettingsSidebarVisible,
      isWithdrawColumnsDataVisible,
      isDepositColumnsDataVisible,
      geoPaymentMethodsFormRules,
    };
  },
});
