import { FormInstance } from 'element-plus';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import {
  TransactionFilterHandlers,
  TransactionFilterKey,
  TransactionListFilterModel,
} from '@/models/transaction/transaction-list-filter.model';
import { useTransactionListStore } from '@/store/transaction/transaction-list.store';
import { useTransactionListSummaryStore } from '@/store/transaction/transaction-list-summary.store';
import { clearObj } from '@/utils/form.utils';
import useTokenPagination from '@/hooks/use-token-pagination.hook';
import { usePaymentOperationListStore } from '@/store/dictionary/payment-operation-list.store';
import { useCountryListStore } from '@/store/dictionary/country-list.store';
import { useCurrencyListStore } from '@/store/dictionary/currency-list.store';
import { usePaymentListStore } from '@/store/dictionary/payment-list.store';
import { usePaymentSystemListStore } from '@/store/dictionary/payment-system-list.store';
import { usePaymentAccountListStore } from '@/store/dictionary/payment-account-list.store';
import { useBillingProviderListStore } from '@/store/dictionary/billing-provider-list.store';
import { useTransactionOperatingSystemListStore } from '@/store/dictionary/transaction-operating-system-list-store';

type TransactionListFilterStore = {
  filterFormData: TransactionListFilterModel;
  submittedFilterFormData: TransactionListFilterModel;
  isFilterActive: boolean;
  isFilterVisible: boolean;
  activeFiltersCount: number;
}

export const tranactionListFilterPopoverRef = ref<HTMLElement>();
export const tranactionListFilterFormRef = ref<FormInstance>();

export const useTransactionListFilterStore = defineStore({
  id: 'TransactionListFilterStore',
  state: (): TransactionListFilterStore => ({
    filterFormData: {},
    submittedFilterFormData: {},
    isFilterActive: false,
    isFilterVisible: false,
    activeFiltersCount: 0,
  }),
  actions: {
    setFilterActive(isActive: boolean): void {
      this.isFilterActive = isActive;
    },
    setFilterFormData(filterData: TransactionListFilterModel): void {
      this.filterFormData = { ...filterData };
      this.submittedFilterFormData = { ...filterData };
      this.activeFiltersCount = Object.keys(this.submittedFilterFormData).length;
    },
    clearFilterFormData(): void {
      this.filterFormData = {};
      this.submittedFilterFormData = {};
      this.activeFiltersCount = 0;
      this.prepareBillingFilterDictionaries();
    },
    prepareFilterDictionaries(): void {
      const { getPaymentOperationList } = usePaymentOperationListStore();
      const { getCountryList } = useCountryListStore();
      const { getCurrencyList } = useCurrencyListStore();
      const { getPaymentAccountList } = usePaymentAccountListStore();
      const { getTransactionOperatingSystemList } = useTransactionOperatingSystemListStore();

      getPaymentOperationList();
      getCountryList();
      getCurrencyList();
      getPaymentAccountList();
      getTransactionOperatingSystemList();
      this.prepareBillingFilterDictionaries();
    },
    prepareBillingFilterDictionaries(): void {
      const { getPaymentList } = usePaymentListStore();
      const { getPaymentSystemList } = usePaymentSystemListStore();
      const { getBillingProviderList } = useBillingProviderListStore();

      getPaymentList();
      getPaymentSystemList();
      getBillingProviderList();
    },
    openFilter(): void {
      this.prepareFilterDictionaries();

      this.isFilterVisible = true;
    },
    closeFilter(): void {
      this.isFilterVisible = false;

      if (!this.isFilterActive) {
        this.clearFilterFormData();

        return;
      }

      this.filterFormData = { ...this.submittedFilterFormData };
    },
    resetFilter(): void {
      const { getTransactionList } = useTransactionListStore();
      const { getTransactionListSummary } = useTransactionListSummaryStore();
      const { resetPaginationTokens } = useTokenPagination();

      tranactionListFilterFormRef.value?.resetFields();
      this.clearFilterFormData();
      resetPaginationTokens();
      this.isFilterActive = false;
      getTransactionList();
      getTransactionListSummary();
    },
    submitFilter(): void {
      const { getTransactionList } = useTransactionListStore();
      const { getTransactionListSummary } = useTransactionListSummaryStore();
      const { resetPaginationTokens } = useTokenPagination();

      this.isFilterActive = true;
      this.setFilterFormData(clearObj(this.filterFormData));
      resetPaginationTokens();
      getTransactionList();
      getTransactionListSummary();
    },
    changeFilter(fieldKey: TransactionFilterKey): void {
      const { bpId, paymentSystem, psp } = this.filterFormData;

      const { getPaymentList } = usePaymentListStore();
      const { getPaymentSystemList } = usePaymentSystemListStore();
      const { getBillingProviderList } = useBillingProviderListStore();

      const filterChangeActions: TransactionFilterHandlers = {
        bpId: (): void => {
          getPaymentList({ billingProvider: bpId, paymentSystem });
          getPaymentSystemList({ billingProvider: bpId, psp });
        },
        paymentSystem: (): void => {
          getPaymentList({ billingProvider: bpId, paymentSystem });
          getBillingProviderList({ psp, paymentSystem });
        },
        psp: (): void => {
          getBillingProviderList({ psp, paymentSystem });
          getPaymentSystemList({ billingProvider: bpId, psp });
        },
      };

      filterChangeActions[fieldKey]();
    },
  },
});
