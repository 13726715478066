import { HttpMethods, RequestPerformer } from '@/api/api-requests-factory';
import { Ref } from 'vue';
import GetAvailableWhitelabelConfigListRequest from '@/api/multibrand/get-available-whitelabels-config-list.request';

export class MultibrandApiResourse {
  constructor(
    private readonly requestPerformer: RequestPerformer,
    private readonly multibrandApiUrl: Ref<string>,
  ) {}

  public get getAvailableWhitelabelConfigListRequest(): GetAvailableWhitelabelConfigListRequest {
    return new GetAvailableWhitelabelConfigListRequest(
      this.requestPerformer,
      `${this.multibrandApiUrl.value}/whitelabel`,
      HttpMethods.GET,
    );
  }
}
