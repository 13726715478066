import { defineComponent, computed, ref, reactive } from 'vue';
import { storeToRefs } from 'pinia';
import type { FormInstance } from 'element-plus';
import type { CountryForm } from '@/models/preset-settings/add-country-dialog.model';
import type { CountryItem } from '@/models/dictionary/country-list.model';
import { PresetSettingsButtonName } from '@/models/preset-settings/preset-settings.model';
import { useCountryListStore } from '@/store/dictionary/country-list.store';
import { presetSettingsValidationRules } from '@/hooks/preset-settings/preset-settings-validation-rules';
import { usePresetSettingsStore } from '@/store/preset-settings/preset-settings.store';

export default defineComponent({
  name: 'AddCountryDialog',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(_, { emit }) {
    const presetSettingsStore = usePresetSettingsStore();
    const { cashierConfigList, isCreateCashierConfigLoading } = storeToRefs(presetSettingsStore);
    const { createCashierConfig, setSelectedCountry } = presetSettingsStore;

    const { countryList } = storeToRefs(useCountryListStore());

    const addCountryForm = ref<FormInstance>();

    const modalForm = reactive<CountryForm>({
      country: '',
    });

    const availableCountryList = computed<CountryItem[]>(() => {
      const existedCountryList = cashierConfigList.value.map(config => config.country);

      return countryList.value
        .filter(country => !existedCountryList.some(existedCountry => country.code === existedCountry));
    });

    const isAvailableCountryList = computed<boolean>(() => Boolean(availableCountryList.value.length));

    const closeAddCountryDialog = (): void => {
      emit('update:modelValue', false);
    };

    const addCountry = (form?: FormInstance): void => {
      if (!form) return;

      form.validate(isValid => {
        if (!isValid) return;
        const countryName =
          availableCountryList.value.find(country => country.code === modalForm.country)?.name ?? '';

        createCashierConfig(modalForm.country, countryName)
          .then(() => {
            setSelectedCountry(modalForm.country);
            form.resetFields();
            closeAddCountryDialog();
          });
      });
    };

    return {
      modalForm,
      addCountry,
      addCountryForm,
      availableCountryList,
      isAvailableCountryList,
      closeAddCountryDialog,
      PresetSettingsButtonName,
      presetSettingsValidationRules,
      isCreateCashierConfigLoading,
    };
  },
});
