import moment from 'moment-timezone';

enum DateTimePropertiesEnum {
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  createdAtTo = 'createdAtTo',
  createdAtFrom = 'createdAtFrom',
  updatedAtTo = 'updatedAtTo',
  updatedAtFrom = 'updatedAtFrom',
}

export const formatUnixToString = (unixTimestamp: number | null, includeTime = false): string => {
  if (!unixTimestamp) return '';

  const outputFormat = includeTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
  const momentUnix = moment.unix(unixTimestamp);

  return momentUnix.isValid() ? momentUnix.format(outputFormat) : '';
};

export const formatStringToUnix = (dateString?: string | number | null): number => {
  if (!dateString) return 0;

  const momentString = moment(dateString, 'YYYY-MM-DD HH:mm:ss');

  return momentString.isValid() ? momentString.unix() : 0;
};

export const formatDatesInPayload = (payloadData) => {
  const resultData = { ...payloadData };

  Object.values(DateTimePropertiesEnum).forEach(prop => {
    if (!resultData.payload[prop]) return;

    resultData.payload[prop] = resultData.unixToString
      ? formatUnixToString(resultData.payload[prop], payloadData.includeTime)
      : formatStringToUnix(resultData.payload[prop]);
  });

  return resultData.payload;
};
