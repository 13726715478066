import { defineComponent, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { usePsManagementCountryListStore } from '@/store/ps-management/payment-methods-country-list.store';
import { useCountryListStore } from '@/store/dictionary/country-list.store';
import { CountryItem } from '@/models/dictionary/country-list.model';
import { ElNotification } from 'element-plus';

export default defineComponent({
  name: 'AddCountriesPopup',
  setup() {
    const usePaymentMethodsCountryListStore = usePsManagementCountryListStore();
    const { isAddCountriesDialogVisible, availableNewCountryList } = storeToRefs(usePaymentMethodsCountryListStore);
    const {
      addPsManagementCountries,
      closeAddCountriesDialog,
    } = usePaymentMethodsCountryListStore;

    const countryListStore = useCountryListStore();
    const { getCountryList } = countryListStore;
    const { isCountryListLoading } = storeToRefs(countryListStore);

    const selectedCountryList = ref<CountryItem[]>([]);

    const addCountries = (selectedCountries: CountryItem[]) => {
      if (!selectedCountries.length) {
        ElNotification({
          title: 'Error',
          message: 'Please select at least one country',
          type: 'error',
        });

        return;
      }
      addPsManagementCountries(selectedCountries);
    };

    return {
      isAddCountriesDialogVisible,
      closeAddCountriesDialog,
      isCountryListLoading,
      availableNewCountryList,
      selectedCountryList,
      addCountries,
      getCountryList,
    };
  },
});
