import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { PaymentSystemListItem, PaymentSystemListPayload } from '@/models/dictionary/payment-system-list.model';

export default class GetPaymentSystemListRequest extends ApiResourceMethod<PaymentSystemListItem[]> {
  public perform(filterList: PaymentSystemListPayload): Promise<PaymentSystemListItem[]> {
    return this.performer.requestWithMapper(
      { params: filterList },
      (payload: BaseResponse<PaymentSystemListItem[]>) => {
        return payload.data;
      },
    );
  }
}
