export enum PAYMENTS_SECTIONS_ROUTES {
  PS_MANAGEMENT = 'ps-management',
  TRANSACTIONS = 'transactions',
  PRESET_SETTINGS = 'preset-settings',
  LOGS = 'logs',
  PSP_SORTING = 'psp-sorting',
}

export enum PS_MANAGEMENT_PERMISSIONS {
  // ====================================
  // === GEO PAYMENT SYSTEMS SETTINGS ===
  // ====================================
  // Общие методы для работы с single- и multi- платежками
  // 🟡 [NEW] GET /billingProvider/paymentMethod/{countyCode}
  // запрос списка single- и multi-платежек для страны
  GET_GEO_PAYMENT_METHOD_LIST_BY_COUNTRY = 'backofficeapi_get_billing_payment_methods_by_county_list',
  // 🟡 [NEW] PUT /billingProvider/paymentMethod/{countyCode}/sorting
  // обновление сортировки списка single- и multi-платежек для страны
  UPDATE_GEO_PAYMENT_METHOD_LIST_SORTING = 'backofficeapi_put_sorting_billing_payment_methods_by_county',

  // SINGLE METHODS
  // ❌ [DEPRECATED] запрос списка платежек для конкретной страны (выбранное значение geo в селекте)
  GET_GEO_PAYMENT_METHOD_LIST = 'backofficeapi_get_billing_payment_method_settings_list',
  // ❌ [DEPRECATED] обновление ЦЕЛИКОМ ВСЕГО списка платежек для конкретной страны
  UPDATE_GEO_PAYMENT_METHOD_LIST = 'backofficeapi_save_billing_payment_method_settings_list',
  // ❌ [DEPRECATED] создание платежки для конкретной страны
  CREATE_GEO_PAYMENT_METHOD = 'backofficeapi_create_billing_payment_method_settings',
  // 🟡 [NEW] POST /billingProvider/paymentMethod/{countyCode}
  // создание single-платежек для страны
  CREATE_GEO_SINGLE_PAYMENT_METHOD = 'backofficeapi_create_billing_payment_method_settings_by_county',
  // обновление полей depositEnabled и withdrawEnabled платежки для конкретной страны
  UPDATE_GEO_PAYMENT_METHOD_DEPOSIT_AND_WITHDRAW = 'backofficeapi_switch_billing_payment_method_settings_operation',
  // удаление ОДНОЙ (не списка, а именно одной — ошибка в названии пермишена) платежки для конкретной страны
  DELETE_GEO_PAYMENT_METHOD = 'backofficeapi_delete_billing_payment_method_settings_list',
  // обновление настроек депозит-лимитов для платежек конкретной страны
  UPDATE_GEO_PAYMENT_METHOD_DEPOSIT_LIMITS = 'backofficeapi_save_billing_payment_method_deposit_limit',
  // запрос настроек депозит-лимитов для платежек конкретной страны
  GET_GEO_PAYMENT_METHOD_DEPOSIT_LIMITS = 'backofficeapi_get_billing_payment_method_deposit_limit',
  // запрос списка настроек депозит-лимитов для платежек конкретной страны
  GET_GEO_PAYMENT_METHOD_DEPOSIT_LIMITS_LIST = 'backofficeapi_get_billing_payment_method_deposit_limit_list',
  // обновление данных гео-платежки по айдишнику
  UPDATE_GEO_PAYMENT_METHOD = 'backofficeapi_update_billing_payment_method_settings_list',

  // MULTI METHODS
  // 🟡 [NEW] GET /billingProvider/paymentMultiMethod/{multiMethodId}
  // получение данных multi-платежки по айдишнику
  GET_MULTI_PAYMENT_METHOD = 'backofficeapi_get_billing_payment_multi_method',
  // 🟡 [NEW] POST /billingProvider/paymentMultiMethod
  // создание multi-платежек для страны
  CREATE_MULTI_PAYMENT_METHOD = 'backofficeapi_create_billing_payment_multi_method',
  // 🟡 [NEW] PUT /billingProvider/paymentMultiMethod/{multiMethodId}
  // обновление данных multi-платежки по айдишнику
  UPDATE_MULTI_PAYMENT_METHOD = 'backofficeapi_update_billing_payment_multi_method',
  // 🟡 [NEW] DELETE /billingProvider/paymentMultiMethod/{multiMethodId}
  // удаление multi-платежки по айдишнику
  DELETE_MULTI_PAYMENT_METHOD = 'backofficeapi_delete_billing_payment_multi_method',
  // 🟡 [NEW] GET /billingProvider/paymentMultiMethod/{multiMethodId}/availableBillingPaymentMethodList
  // Получение списка доступных для добавления методов в мульти-методы
  GET_AVAILABLE_BILLING_METHOD_LIST = 'backofficeapi_get_available_billing_payment_method_list',
  // 🟡 [NEW] GET /billingProvider/paymentMultiMethod/{multiMethodId}/billingPaymentMethodList
  // Получение списка настроенных платежных методов  в мультиметоде
  GET_ASSOCIATED_BILLING_METHOD_LIST = 'backofficeapi_get_associated_billing_payment_method_list',
  // 🟡 [NEW] POST /billingProvider/paymentMultiMethod/{multiMethodId}/billingPaymentMethod
  // Добавление платежных методов в мультиметод
  ASSOCIATE_BILLING_METHODS = 'backofficeapi_associate_billing_payment_method',
  // 🟡 [NEW] PUT /billingProvider/paymentMultiMethod/{multiMethodId}/billingPaymentMethod
  // Изменение настроенного платежного метода в мультиметоде
  UPDATE_ASSOCIATED_BILLIND_METHOD = 'backofficeapi_update_associated_billing_payment_method',
  // 🟡 [NEW] DELETE /billingProvider/paymentMultiMethod/{multiMethodId}/billingPaymentMethod/{billingPaymentMethodId}
  // Удаление настроенного платежного метода в мультиметоде
  DISASSOCIATE_BILLING_METHOD = 'backofficeapi_disassociate_billing_payment_method',

  // ================================
  // === PAYMENT SYSTEMS GEO LIST ===
  // ================================
  // запрос списка гео для селекта
  GET_PAYMENT_METHOD_GEO_LIST = 'backofficeapi_get_payment_method_geo_list',
  // добавление гео в селект
  ADD_PAYMENT_METHOD_GEO = 'backofficeapi_add_payment_method_geo',

  // =======================================
  // === GLOBAL PAYMENT SYSTEMS SETTINGS ===
  // =======================================
  // запрос списка платежек для всех стран
  GET_GLOBAL_PAYMENT_METHOD_LIST = 'backofficeapi_get_billing_payment_method_list',
  // создание платежки для всех стран
  CREATE_GLOBAL_PAYMENT_METHOD = 'backofficeapi_create_billing_payment_method',
  // удаление платежки для всех стран
  DELETE_GLOBAL_PAYMENT_METHOD = 'backofficeapi_delete_billing_payment_method',
  // запрос платежки для всех стран по айдишнику
  GET_GLOBAL_PAYMENT_METHOD = 'backofficeapi_get_billing_payment_method',
  // обновление платежки для всех стран по айдишнику
  UPDATE_GLOBAL_PAYMENT_METHOD = 'backofficeapi_update_billing_payment_method',
  // обновление полей depositEnabled и withdrawEnabled платежки для всех стран
  UPDATE_GLOBAL_PAYMENT_METHOD_DEPOSIT_AND_WITHDRAW = 'backofficeapi_switch_billing_payment_method_operation',
}

export enum TRANSACTIONS_PERMISSIONS {
  TRANSACTION_MODERATE = 'backofficeapi_billing_transaction_moderate',
  GET_PAYMENT_LIST = 'backofficeapi_whitelabel_get_payment_list',
  GET_PAYMENT_LIST_TOTAL = 'backofficeapi_whitelabel_get_payment_list_total',
  GET_PAYMENT_LIST_CSV = 'backofficeapi_whitelabel_get_payment_list_csv',
}

export enum PRESET_SETTINGS_PERMISSIONS {
  // вывод списка настроек cashier config
  GET_CONFIG_LIST = 'backofficeapi_get_cashier_config_list',
  // вывод конкретной настройки cashier config
  GET_CONFIG = 'backofficeapi_get_cashier_config',
  // обновление конкретной настройки cashier config
  UPDATE_CASHIER_CONFIG = 'backofficeapi_update_cashier_config',
  // создание новой настройки cashier config
  CREATE_CASHIER_CONFIG = 'backofficeapi_create_cashier_config',
}

export enum PSP_SORTING_PERMISSIONS {
  GET_PSP_SORTING_LIST = 'backofficeapi_cashier_get_payment_method_sort',
}

export enum LOGS_PERMISSIONS {
  GET_BILLING_SETTINGS_CHANGE_HISTORY = 'backofficeapi_get_billing_provider_settings_change_history',
  GET_BILLING_SETTINGS_CHANGE_HISTORY_CSV = 'backofficeapi_get_billing_provider_settings_change_history_csv',
  GET_BILLING_SETTINGS_CHANGE_FILTER_LIST = 'backofficeapi_get_billing_provider_settings_change_filter_list',
}

export type PaymentsPermissions = {
  [PAYMENTS_SECTIONS_ROUTES.PS_MANAGEMENT]: { [key in PS_MANAGEMENT_PERMISSIONS]: boolean };
  [PAYMENTS_SECTIONS_ROUTES.TRANSACTIONS]: { [key in TRANSACTIONS_PERMISSIONS]: boolean };
  [PAYMENTS_SECTIONS_ROUTES.PRESET_SETTINGS]: { [key in PRESET_SETTINGS_PERMISSIONS]: boolean };
  [PAYMENTS_SECTIONS_ROUTES.LOGS]: { [key in LOGS_PERMISSIONS]: boolean };
  [PAYMENTS_SECTIONS_ROUTES.PSP_SORTING]: { [key in PSP_SORTING_PERMISSIONS]: boolean };
};
