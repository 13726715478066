import { useGlobalPsManagementStore } from '@/store/ps-management/global-payment-methods.store';
import { storeToRefs } from 'pinia';
import { defineAsyncComponent, defineComponent, ref } from 'vue';
import { EditPen, QuestionFilled, Key, Setting } from '@element-plus/icons-vue';
import {
  GlobalPaymentMethodColumnProperties,
  GlobalPaymentMethodItem,
  GlobalPaymentMethodPartialUpdateParameterKeyModel,
  GlobalPaymentMethodPartialUpdateParams,
  globalPaymentMethodColumnsList,
  DropdownActionCommand,
} from '@/models/ps-management/ps-management.model';
import PsLimitsDetails from '@/layouts/ps-management-page/ps-limits-details/ps-limits-details.vue';
import { usePermissionsStore } from '@/store/permissions.store';
import { PS_MANAGEMENT_PERMISSIONS } from '@/models/app-internal/permissions.model';
import { ElMessageBox } from 'element-plus';
import { usePaymentTypeListStore } from '@/store/dictionary/payment-type-list.store';

const icons = {
  EditPen,
  QuestionFilled,
  Key,
  Setting,
};

export default defineComponent({
  name: 'GlobalPaymentMethodsList',
  components: {
    PsLimitsDetails,
    AddSecretKeySidebar: defineAsyncComponent(
      () => import('@/layouts/ps-management-page/add-secret-key-sidebar/add-secret-key-sidebar.vue'),
    ),
  },
  setup() {
    const globalPsManagementStore = useGlobalPsManagementStore();
    const {
      partialUpdateGlobalPaymentMethod,
      openGeneralPaymentMethodSettings,
    } = globalPsManagementStore;
    const {
      globalPaymentMethodList,
      isGlobalPaymentMethodListLoading,
    } = storeToRefs(globalPsManagementStore);

    const updatePaymentMethodSwitch = (payload: GlobalPaymentMethodPartialUpdateParams) => {
      partialUpdateGlobalPaymentMethod(payload);
    };

    const { psManagementPermissions } = storeToRefs(usePermissionsStore());

    const { getPaymentTypeLabel } = usePaymentTypeListStore();

    const visibleDrawer = ref(false);
    const selectedPaymentMethod = ref<GlobalPaymentMethodItem | null>(null);

    const switchPaymentMethod = (
      currentRow: GlobalPaymentMethodItem,
      currentColumn: GlobalPaymentMethodPartialUpdateParameterKeyModel,
    ) => {
      ElMessageBox.confirm(
        'Are you sure you want to toggle the PS for the casino?',
        'Warning',
        {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning',
        },
      )
        .then(() => {
          updatePaymentMethodSwitch({
            parameterKey: currentColumn,
            billingPaymentMethodId: currentRow.billingPaymentMethodId,
            newValue: currentRow[currentColumn],
          });
        })
        .catch(() => {
          const globalPaymentMethod = globalPaymentMethodList.value
            .find((globalPaymentMethod) =>
              globalPaymentMethod.billingPaymentMethodId === currentRow.billingPaymentMethodId);

          if (!globalPaymentMethod) {
            return;
          }

          globalPaymentMethod[currentColumn] = !currentRow[currentColumn];
        });
    };

    const addSecretKeyLabel = (secretsAdded: GlobalPaymentMethodItem['secretsAdded']) => {
      return secretsAdded ? 'Add new secret key' : 'Add secret key';
    };

    const visibleAddSecretKeyDrawer = (row: GlobalPaymentMethodItem) => {
      selectedPaymentMethod.value = row;

      visibleDrawer.value = !visibleDrawer.value;
    };

    const handleDropdownActionCommand = (action: DropdownActionCommand, row: GlobalPaymentMethodItem) => {
      const actions: Record<DropdownActionCommand, () => void> = {
        [DropdownActionCommand.OPEN_EDIT_PAYMENT_METHOD]: () =>
          openGeneralPaymentMethodSettings(row.billingPaymentMethodId),
        [DropdownActionCommand.OPEN_ADD_SECRET_KEY]: () => visibleAddSecretKeyDrawer(row),
      };

      actions[action]();
    };

    const isUpdateGlobalPaymentMethodAllowed =
      psManagementPermissions.value[PS_MANAGEMENT_PERMISSIONS.UPDATE_GLOBAL_PAYMENT_METHOD];
    const isPartialUpdateGlobalPaymentMethodAllowed =
      psManagementPermissions.value[PS_MANAGEMENT_PERMISSIONS.UPDATE_GLOBAL_PAYMENT_METHOD_DEPOSIT_AND_WITHDRAW];

    return {
      icons,
      isGlobalPaymentMethodListLoading,
      globalPaymentMethodList,
      globalPaymentMethodColumnsList,
      GlobalPaymentMethodColumnProperties,
      updatePaymentMethodSwitch,
      openGeneralPaymentMethodSettings,
      isUpdateGlobalPaymentMethodAllowed,
      isPartialUpdateGlobalPaymentMethodAllowed,
      switchPaymentMethod,
      getPaymentTypeLabel,
      addSecretKeyLabel,
      handleDropdownActionCommand,
      DropdownActionCommand,
      visibleDrawer,
      selectedPaymentMethod,
    };
  },
});
