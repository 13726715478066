import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "add-secret-key-drawer__alert"
}
const _hoisted_2 = { class: "add-secret-key-drawer__example" }
const _hoisted_3 = { class: "add-secret-key-drawer__example-json" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createBlock(_component_el_drawer, {
    modelValue: _ctx.visible,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visible) = $event)),
    "append-to-body": "",
    title: _ctx.title,
    "before-close": _ctx.beforeClose
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_el_button, {
        loading: _ctx.isSubmitFormLoading,
        plain: "",
        onClick: _ctx.beforeClose
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Cancel ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["loading", "onClick"]),
      _createVNode(_component_el_popconfirm, {
        title: "Are you sure?",
        width: "224",
        "cancel-button-text": "No, thanks",
        "confirm-button-text": "OK",
        icon: _ctx.icons.InfoFilled,
        "confirm-button-type": "primary",
        onConfirm: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submitForm(_ctx.formRef)))
      }, {
        reference: _withCtx(() => [
          _createVNode(_component_el_button, {
            loading: _ctx.isSubmitFormLoading,
            type: "success"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Save ")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["loading"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["icon"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (_ctx.secretsAdded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_el_alert, {
                type: "info",
                title: "The secret key is already configured.",
                "show-icon": "",
                closable: false
              }),
              _createVNode(_component_el_alert, {
                type: "warning",
                title: "You are now adding a new secret key.",
                "show-icon": "",
                closable: false
              })
            ]))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_el_text, {
          class: "add-secret-key-drawer__label",
          tag: "div"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Data should be entered in JSON format e.g. ")
          ]),
          _: 1 /* STABLE */
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_el_text, { tag: "code" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.secretsFormat), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _createVNode(_component_el_button, {
            class: "add-secret-key-drawer__example-button",
            disabled: _ctx.isSubmitFormLoading,
            type: "primary",
            size: "small",
            bg: "",
            text: "",
            icon: _ctx.icons.CopyDocument,
            onClick: _ctx.createCleanedSecretExample
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Use example ")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled", "icon", "onClick"])
        ])
      ]),
      _createVNode(_component_el_form, {
        ref: "formRef",
        "label-position": "top",
        model: _ctx.formData,
        rules: _ctx.rules,
        disabled: _ctx.isSubmitFormLoading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: "Basic form",
            prop: "secret",
            required: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.formData.secret,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.secret) = $event)),
                class: "add-secret-key-drawer__textarea",
                type: "textarea",
                placeholder: "Enter the secret JSON or click the Use example button",
                autosize: ""
              }, null, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_form_item, {
            label: "Two factor authentication",
            prop: "twoFactorCode",
            required: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.formData.twoFactorCode,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.twoFactorCode) = $event)),
                placeholder: "Please enter your 2FA code",
                "prefix-icon": _ctx.icons.Lock,
                type: "number"
              }, null, 8 /* PROPS */, ["modelValue", "prefix-icon"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["model", "rules", "disabled"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title", "before-close"]))
}