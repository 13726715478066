import { PAYMENTS_SECTIONS_ROUTES } from './permissions.model';

export enum PAYMENTS_META_TITLES {
  PS_MANAGEMENT = 'PS Management',
  TRANSACTIONS = 'Transactions',
  PRESET_SETTINGS = 'Preset settings',
  PSP_SORTING = 'PSP sorting',
}

export const PAYMENTS_SECTIONS_ROUTES_META_TITLES = {
  [PAYMENTS_SECTIONS_ROUTES.PS_MANAGEMENT]: PAYMENTS_META_TITLES.PS_MANAGEMENT,
  [PAYMENTS_SECTIONS_ROUTES.TRANSACTIONS]: PAYMENTS_META_TITLES.TRANSACTIONS,
  [PAYMENTS_SECTIONS_ROUTES.PRESET_SETTINGS]: PAYMENTS_META_TITLES.PRESET_SETTINGS,
  [PAYMENTS_SECTIONS_ROUTES.PSP_SORTING]: PAYMENTS_META_TITLES.PSP_SORTING,
};
