import { globalPaymentMethodsFormRules } from '@/hooks/ps-management/global-payment-methods-validation-rules';
import { HintNames } from '@/models/hint.model';
import { useBillingProviderListStore } from '@/store/ps-management/billing-provider-list.store';
import {
  globalPaymentMethodsCurrencyFormRef,
  globalPaymentMethodsSettingsFormRef,
  useGlobalPsManagementStore,
} from '@/store/ps-management/global-payment-methods.store';
import { storeToRefs } from 'pinia';
import { computed, defineComponent, onMounted, ref } from 'vue';
import HintComponent from '@/components/hint/hint.vue';
// eslint-disable-next-line max-len
import LimitCurrencyConfiguration from '@/layouts/ps-management-page/global-payment-methods-settings/limit-currency-configuration/limit-currency-configuration.vue';
import UploadImageComponent from '@/components/upload-image-component/upload-image-component.vue';
import SegmentsAutocompleteMultiselect
  from '@/components/segments-autocomplete-multiselect/segments-autocomplete-multiselect.vue';
import { ElNotification, FormInstance } from 'element-plus';
import { useUploadImageStore } from '@/store/upload-image.store';
import { UpdateGlobalPaymentMethodData } from '@/models/ps-management/ps-management.model';
import { useCurrencyListStore } from '@/store/dictionary/currency-list.store';
import { usePaymentTypeListStore } from '@/store/dictionary/payment-type-list.store';

export default defineComponent({
  name: 'GlobalPaymentMethodsSettings',
  components: {
    HintComponent,
    LimitCurrencyConfiguration,
    UploadImageComponent,
    SegmentsAutocompleteMultiselect,
  },
  emits: ['updatePaymentMethodList'],
  setup(_props, { emit }) {
    const globalPsManagementStore = useGlobalPsManagementStore();
    const {
      globalPaymentMethodsSettingsFormData,
      isGlobalPaymentMethodSettingsSidebarVisible,
      currentBillingPaymentMethodId,
      isDepositEnabled,
      billingProviderId,
      isWithdrawEnabled,
      isGlobalPaymentMethodSettingsLoading,
    } = storeToRefs(globalPsManagementStore);
    const {
      createGlobalPaymentMethod,
      closeGlobalPaymentMethodSettings,
      updateGlobalPaymentMethod,
    } = globalPsManagementStore;

    const billingProviderListStore = useBillingProviderListStore();
    const { getBillingProviderList } = billingProviderListStore;
    const { billingProviderList, isBillingProviderListLoading } = storeToRefs(billingProviderListStore);

    const uploadImageStore = useUploadImageStore();
    const { uploadFile } = uploadImageStore;
    const { isSelectedImage, selectedImage, isUploading: isLogoUploading } = storeToRefs(uploadImageStore);

    const currencyStore = useCurrencyListStore();
    const { isCurrencyListLoading } = storeToRefs(currencyStore);
    const { getCurrencyList } = currencyStore;

    const paymentTypeStore = usePaymentTypeListStore();
    const { paymentTypeList } = storeToRefs(paymentTypeStore);

    const isGlobalPaymentMethodsFormDataLoading = computed(() =>
      isBillingProviderListLoading.value ||
      isGlobalPaymentMethodSettingsLoading.value ||
      isCurrencyListLoading.value,
    );

    const pspList = ref<string[]>([]);

    const isCreatingGlobalPaymentMethod = computed(() => !currentBillingPaymentMethodId.value);
    const sidebarTitle = computed(() => `${isCreatingGlobalPaymentMethod.value ? 'Create' : 'Edit'} payment method`);

    const isEditablePsp = computed(
      () => isDepositEnabled.value || isWithdrawEnabled.value,
    );

    onMounted(() => {
      getBillingProviderList();
      getCurrencyList();
    });

    const clearPspList = (): void => {
      globalPaymentMethodsSettingsFormData.value.psp = '';
      pspList.value = [];
      globalPaymentMethodsSettingsFormData.value.depositEnabled = false;
      globalPaymentMethodsSettingsFormData.value.withdrawEnabled = false;
    };

    const toggleAutoWithdrawAvailable = (paymentTypeList: string[]) => {
      const hasCrypto = paymentTypeList.includes('crypto');

      globalPaymentMethodsSettingsFormData.value.autoWithdrawAvailable = !hasCrypto && paymentTypeList.length > 0;
    };

    const changePspList = (): void => {
      globalPaymentMethodsSettingsFormData.value.psp = '';

      const billingProviderItem = billingProviderList.value.find(
        bpItem => bpItem.bpId === billingProviderId.value,
      );

      if (!billingProviderItem) {
        return;
      }

      if (isDepositEnabled.value) {
        pspList.value = [];
        const depositMethodList = billingProviderItem.depositMethodList || [];
        setUniquePspList(depositMethodList);
      } else if (isWithdrawEnabled.value) {
        pspList.value = [];
        const withdrawMethodList = billingProviderItem.withdrawMethodList || [];
        setUniquePspList(withdrawMethodList);
      }

      if (isWithdrawEnabled.value && isDepositEnabled.value) {
        pspList.value = billingProviderItem.depositMethodList.filter(method =>
          billingProviderItem.withdrawMethodList.includes(method),
        );
      }
    };

    const setUniquePspList = (newPspList: string[]): void => {
      const uniquePspList = new Set(newPspList.map(psp => psp));

      pspList.value = Array.from(uniquePspList) as [];
    };

    const submitData = () => {
      if (isCreatingGlobalPaymentMethod.value) {
        if (!isSelectedImage.value) {
          ElNotification({
            title: 'Error',
            message: 'Logo not added',
            type: 'error',
          });

          return;
        }

        return uploadFile().then((uploadedFilePath) => {
          if (!uploadedFilePath) {
            return;
          }

          globalPaymentMethodsSettingsFormData.value.imageId = uploadedFilePath.uploadedImageFileId;

          if (!globalPaymentMethodsSettingsFormData.value.imageId) {
            ElNotification({
              title: 'Error',
              message: 'Logo not added',
              type: 'error',
            });
          }

          createGlobalPaymentMethod(globalPaymentMethodsSettingsFormData.value)
            .then(() => emit('updatePaymentMethodList'));
        });
      } else {
        if (selectedImage.value) {
          return uploadFile().then((uploadedFilePath) => {
            if (!uploadedFilePath) {
              return;
            }

            globalPaymentMethodsSettingsFormData.value.imageId = uploadedFilePath.uploadedImageFileId;
            updateGlobalPaymentMethod(globalPaymentMethodsSettingsFormData.value as UpdateGlobalPaymentMethodData)
              .then(() => {
                emit('updatePaymentMethodList');
              });
          });
        }

        return updateGlobalPaymentMethod(globalPaymentMethodsSettingsFormData.value as UpdateGlobalPaymentMethodData)
          .then(() => emit('updatePaymentMethodList'));
      }
    };

    const saveGlobalPaymentMethods = (globalForm: FormInstance | undefined): void => {
      if (!globalForm) return;

      globalForm.validate((isGlobalSettingsFormValid) => {
        if (!isGlobalSettingsFormValid) {
          return;
        }

        if (!globalPaymentMethodsCurrencyFormRef.value) {
          submitData();

          return;
        }

        globalPaymentMethodsCurrencyFormRef.value?.validate((isCurrencyFormValid) => {
          const isSomeCurrenciesAdded = globalPaymentMethodsSettingsFormData.value.depositLimitList.length ||
          globalPaymentMethodsSettingsFormData.value.withdrawLimitList.length;

          if (!isGlobalSettingsFormValid) {
            return;
          }

          if (isSomeCurrenciesAdded && !isCurrencyFormValid) {
            return;
          }

          submitData();
        });
      });
    };

    return {
      globalPaymentMethodsSettingsFormData,
      globalPaymentMethodsSettingsFormRef,
      globalPaymentMethodsFormRules,
      isGlobalPaymentMethodsFormDataLoading,
      billingProviderList,
      clearPspList,
      changePspList,
      HintNames,
      isEditablePsp,
      pspList,
      saveGlobalPaymentMethods,
      billingProviderId,
      isGlobalPaymentMethodSettingsSidebarVisible,
      sidebarTitle,
      isCreatingGlobalPaymentMethod,
      isLogoUploading,
      closeGlobalPaymentMethodSettings,
      paymentTypeList,
      toggleAutoWithdrawAvailable,
    };
  },
});
