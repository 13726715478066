import type { Ref } from 'vue';
import { HttpMethods, RequestPerformer } from '@/api/api-requests-factory';
import GetPspSortingListRequest from '@/api/psp-sorting/get-psp-sorting-list.request';

export class PspSortingApiResources {
  constructor(
    private readonly requestPerformer: RequestPerformer,
    private readonly multibrandApiUrl: Ref<string>,
  ) { }

  public get getPspSortingListRequest(): GetPspSortingListRequest {
    return new GetPspSortingListRequest(
      this.requestPerformer,
      `${this.multibrandApiUrl.value}/pspSorting`,
      HttpMethods.GET,
    );
  }
}
