<template>
  <el-container>
    <el-header class="permissions-page__header">
      <h3 class="permissions-page__header_title">
        Hello, I'm bo-payments-app-ui permission page
      </h3>
      <hr>
    </el-header>
    <div>
      <p>Backoffice api url: <b>{{ BACKOFFICE_API_URL }}</b></p>
      <p>Backoffice api token: <b>{{ token ? '✅' : '❌' }}</b></p>
      <p>WL SLUG: <b>{{ WL_SLUG }}</b></p>
      <p>Current parrent route name: <b>{{ CURRENT_PARTENT_ROUTE_NAME }}</b></p>
    </div>
    <div>
      <h3>Sections permissions</h3>
      <br>
      <p>
        <ul>
          <li v-for="(sectionPermissions, sectionName) in paymentsPermissions" :key="sectionName">
            <h4>
              {{ sectionName }}
            </h4>
            <p>
              Permissions list:
              <ul>
                <li v-for="(permissionValue, permissionName) in sectionPermissions" :key="permissionName">
                  {{ permissionValue ? '✅' : '❌' }}&nbsp;&nbsp;&nbsp;{{ permissionName }}
                </li>
              </ul>
            </p>
            <hr>
          </li>
        </ul>
      </p>
    </div>
  </el-container>
</template>

<script src="./permissions-page.ts" />
<style src="./permissions-page.scss" />
